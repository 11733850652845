import React from 'react';
import moment from 'moment';
import { ApiManager, AuthApi, centsToDecimal, DateService, useSettings } from '@apollo/core';
import { BET_EVENT_TYPE } from '@apollo/core/src/constants';
import Button from '../../../../shared/components/Button/Button';
import {
  arrayToCsv,
  downloadFile,
  formatOdds,
  getCurrencySymbol,
  multiBetLabels,
  transactionPropertiesParser,
} from '../../../../core/utils';
import { ALL_ITEMS, negativeBalanceTypes, transactionTypes } from '../../../../core/constants';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { getDayRange, getTwoDayRange, last24, last48 } from '../constants';
import FileSystemAdapter from '../../../../core/Cordova/FileSystemAdaptor';
import ToastManager from '../../../../core/ToastManager';
import Label from '../../../../shared/components/I18n/Label';

const { WITHDRAWAL_COMPLETE } = transactionTypes;

const TransactionHistoryExport = ({
  activeTimeFilter,
  activeTypeFilters,
  range,
  label = 'export',
  className = 'btn',
}) => {
  const { isCordova } = useSettings();
  const t = useTranslate();

  let createTime = range;
  if (activeTimeFilter === last24) {
    createTime = getDayRange();
  } else if (activeTimeFilter === last48) {
    createTime = getTwoDayRange();
  }

  const downloadTransactionCsv = () => {
    const baseUrl = ApiManager.getHostname('core');
    const token = ApiManager.getAuthorizationToken();
    const passThroughToken = btoa(`${token}:2`);
    // todo: Include date range and filters
    window.open(`${baseUrl}client/client/transactions:csv/${passThroughToken}`, '_blank');
  };

  const exportToCSV = () => {
    AuthApi.getClientTransactionHistory({
      filter: {
        types: (activeTypeFilters || []).filter((f) => f != null),
        createTime,
      },
      paging: {
        itemsPerPage: ALL_ITEMS,
        currentPage: 0,
      },
      sorting: [
        {
          direction: 'DESC',
          fieldName: 'createTime',
          order: 0,
        },
      ],
    })
      .then((res) => {
        const csvHeader = [
          t('date'),
          t('transaction_type'),
          t('transaction_id'),
          t('id'),
          t('event'),
          t('odds'),
          t('amount'),
          t('running_balance'),
        ];

        const getMultiBetTitle = (outcomes) => {
          if (outcomes.length > 1) {
            const multiBetType = multiBetLabels[outcomes.length] || `${outcomes.length} folds`;
            return `${t('multibet')} - ${t(multiBetType)} `;
          }
          return '';
        };

        const parseOutcomeInfo = (outcomes) => {
          let info = '';
          _.map(outcomes, (outcome) => {
            const eventStartTimeDate =
              outcome &&
              outcome.eventStartTime &&
              DateService.getFormattedDateWithTime(outcome.eventStartTime);

            const eventName = outcome.localizedEventName;
            const selection = outcome.localizedOutcomeName;
            const marketName = outcome.marketName || outcome.outcomeName;
            const subTitle = `${outcome.localizedSportName} ${outcome.sportService !== BET_EVENT_TYPE.RACING ? ` - ${outcome.localizedCategoryName}` : ''} - ${outcome.localizedTournamentName} `;
            const { sameGameMultiLegs } = outcome;

            info += `${eventStartTimeDate} `;
            if (selection) {
              if (sameGameMultiLegs?.length) {
                info += ` | ${sameGameMultiLegs?.length} ${t('legs')} ${marketName}: `;
              } else {
                info += `${selection} - ${marketName} `;
              }
            }
            info += ` ${eventName} `;
            /* if (additionalAttribute1 === 'extra-desc') {
                info += ` ${additionalAttribute2}`
              } */

            if (sameGameMultiLegs?.length) {
              sameGameMultiLegs.forEach(
                (leg) => (info += ` ${leg.localizedOutcomeName} ${leg.localizedMarketName} | `)
              );
            }

            info += ` ${subTitle}`;
          });

          return info;
        };

        const data = res.transactions.map((transaction) => {
          const {
            id,
            type,
            amount,
            cashBalanceBefore,
            cashBalanceAfter,
            creationDate,
            currency,
            properties,
          } = transaction;

          const bet = properties?.BET || {};
          const outcomes = transactionPropertiesParser(properties);
          const oddsAmount = formatOdds(bet?.betOdds, 3, 2);
          const calcAmount =
            type !== WITHDRAWAL_COMPLETE && cashBalanceBefore > 0
              ? Math.abs(cashBalanceAfter - cashBalanceBefore)
              : amount;

          const decimalAmount = centsToDecimal(calcAmount);
          const currencySymbol = getCurrencySymbol(currency);

          const decimalCashBalance = centsToDecimal(cashBalanceAfter);

          const date = DateService.getFormattedDate(creationDate);
          const time = DateService.getFormattedTime(creationDate);

          return [
            `${date} ${time}`,
            t(type),
            type?.includes('BET') ? ' - ' : id,
            type?.includes('BET') ? id : ' - ',
            `${getMultiBetTitle(outcomes)}${outcomes.length > 0 ? parseOutcomeInfo(outcomes) : properties?.PM_NAME ?? '-'}`,
            oddsAmount || ' - ',
            `${negativeBalanceTypes[type] ? '-' : ''}${currencySymbol}${decimalAmount}`,
            `${currencySymbol}${decimalCashBalance}`,
          ];
        });

        const str = arrayToCsv(csvHeader, data);
        const fileName = `Transaction-History-${moment().format('YYYY-MM-DD')}.csv`;
        if (isCordova) {
          const fs = new FileSystemAdapter();
          fs.saveFile(fileName, str, 'text/csv')
            .then((data) => {
              ToastManager.success(() => <Label message='file_sent_documents' />);
            })
            .catch((error) => {
              ToastManager.error(() => <Label message='unable_save_file' />);
            });
        } else {
          downloadFile(fileName, str, 'text/csv;charset=utf-8;');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Button
      className={`${className} btn-export`}
      type='button'
      label={label}
      onClick={() => exportToCSV()}
    />
  );
};

export default TransactionHistoryExport;
