import React from 'react';
import { NotificationManager } from '@apollo/core';
import BasePopup from './BasePopup';

const BaseNotification = ({ id, status, title, message, data = {} }) => {
  const handleContinueButtonClick = React.useCallback(() => {
    NotificationManager.removeNotification(id);
  }, [id]);
  const { children, icon, withOkButton, withCancelButton } = data;

  return (
    <BasePopup
      onClose={handleContinueButtonClick}
      type='popup success'
      title={title}
      message={message}
      status={status}
      icon={icon}
      withOkButton={withOkButton}
      withCancelButton={withCancelButton}
    >
      {children && children}
    </BasePopup>
  );
};
export default BaseNotification;
