import React, { useMemo } from 'react';
import _, { get } from 'lodash';
import {
  Bus,
  centsToDecimal,
  getAvailablePages,
  SiteConfigManager,
  useApplicationState,
} from '@apollo/core';
import { availableClientProfileRoutes, COMPONENT_TYPES } from '@apollo/routing';
import Theme from 'themeSource/custom';
import Label from '../../shared/components/I18n/Label';
import StatusTooltip from '../../shared/components/StatusTooltip/StatusTooltip';
import { cmsNavBlock } from '../../core/constants';
import LinkList from './LinkList';
import ResponsibleGamblingPolicyLink from '../../shared/components/ResponsibleGamblingPolicyLink/ResponsibleGamblingPolicyLink';
import LiveChatLink from '../../shared/components/LiveChatLink/LiveChatLink';
import { getCurrencySymbol } from '../../core/utils';

const onLogOut = () => {
  Bus.emit(Bus.events.auth.logout);
};

const { CLIENT_PROFILE } = COMPONENT_TYPES;

const SidebarHead = () => <div className='sidebar-head' />;

const ThemeSidebarHeader = Theme.SidebarHeader || SidebarHead;

const ClientProfileSidebar = () => {
  const applicationState = useApplicationState();

  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);

  const tabs = useMemo(
    () => getAvailablePages(availableClientProfileRoutes, clientProfileTabs),
    [clientProfileTabs]
  );

  const [currency, balance, id, clientVerificationState, bonus] = _.at(applicationState, [
    'wallet.currency',
    'wallet.balance',
    'account.id',
    'account.clientVerificationState',
    'wallet.detailedBalance.BONUS',
  ]);
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);
  const currencySymbol = getCurrencySymbol(currency);
  const decimalBalance = centsToDecimal(balance);
  const decimalBonus = useMemo(() => (_.isNumber(bonus) ? centsToDecimal(bonus) : null), [bonus]);

  const balanceComponent = useMemo(() => {
    if (_.isEmpty(balance) && !_.isFinite(balance)) {
      return (
        <div className='verify-status'>
          <div className='label-with-icon status-label'>
            <Label message='balance_is_unavailable' />
          </div>
          <StatusTooltip message={<Label message='balance_is_unavailable_description' />}>
            <span className='status-label'>?</span>
          </StatusTooltip>
        </div>
      );
    }

    return (
      <div className='balance-info'>
        <div className='balance'>
          <Label className='balance__label' message='balance' />
          <span className='balance__value'>
            {currencySymbol}
            {decimalBalance}
          </span>
        </div>
        {/* {decimalBonus && (
          <div className='balance'>
            <Label message='bonus_balance' />
            <span>
              {currencySymbol}
              {decimalBonus}
            </span>
          </div>
        )} */}
      </div>
    );
  }, [balance, currencySymbol, decimalBalance, decimalBonus]);

  const helpNav = get(SiteConfigManager.navigation, cmsNavBlock.Footer);

  return (
    <div className='main__page__account__aside'>
      <div>
        <ThemeSidebarHeader />
        <div className='client-info-container'>
          <div className='client-info'>
            <Label className='client-info__label' message='User ID' />
            <span className='client-info__value'>{id}</span>
          </div>
          <div className='client-info'>
            <Label className='client-info__label' message='Verification Status' />
            <span className='client-info__value'>{clientVerificationState}</span>
          </div>

          {balanceComponent}
        </div>
        <div className='main__page__account__aside__navigation'>
          <LinkList
            itemClassName='sidebar-tree__section-item'
            array={tabs}
            parentPath={clientProfilePath}
            isScroll={false}
          />
          <div className='sidebar_help'>
            <div className='sidebar-tree__section-title'>
              <Label message='Help' />
            </div>
            <LiveChatLink />
            {helpNav ? (
              <LinkList
                itemClassName='sidebar-tree__section-item'
                array={helpNav[1]?.children?.map((link) => ({
                  link: link.location,
                  name: link.name,
                }))}
                isScroll={false}
              />
            ) : null}
            <ResponsibleGamblingPolicyLink className='sidebar-tree__section-item' />
          </div>
        </div>
      </div>
      <div className='logout-button-container' onClick={onLogOut}>
        <span className='AkIcon-log-out-icon' />
        <Label message='logout' />
      </div>
    </div>
  );
};

export default ClientProfileSidebar;
