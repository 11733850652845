import React from 'react';
import cx from 'classnames';

const ProgressBar = ({ current = 0, required = 100, tooltip = '' }) => {
  const progress = Math.min(100, Math.round((current / required) * 100));

  const classes = cx(
    'progress-bar',
    `progress-bar--${progress}`,
    progress === 0 ? 'progress-bar--none' : null,
    progress >= 100 ? 'progress-bar--complete' : 'progress-bar--incomplete'
  );

  return (
    <div className={classes} title={tooltip}>
      <div className='progress-bar__bar' style={{ width: `${progress}%` }}>
        <span className='progress-bar__current'>{current}</span>
      </div>
      <span className='progress-bar__required'>{required}</span>
    </div>
  );
};
export default React.memo(ProgressBar);
