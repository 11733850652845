import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { CmsApi, useApplicationState, useSettings } from '@apollo/core';
import { buildCdnUrl } from '../../core/utils';
import MainBody from '../../shared/components/MainBody/MainBody';

const PromotionPage = ({
  match: {
    params: { id },
  },
}) => {
  const { lang } = useSettings();
  const { authenticated } = useApplicationState();
  const [promotion, setPromotion] = useState({});

  useEffect(() => {
    const ac = new AbortController();
    CmsApi.getPromotion({ id, signal: ac.signal }).then((payload) => {
      setPromotion(payload);
    });
    return () => ac.abort();
  }, [id, lang, authenticated]);

  return (
    <div className='main__page main__page--promotions'>
      <MainBody className='main__body--promotions'>
        <div className='promotion-detail'>
          {promotion?.cover_image_url && (
            <div className='promotion-detail__image'>
              <img
                src={buildCdnUrl(promotion.cover_image_url, 'LOGOS_CDN_URL')}
                alt=''
                loading='lazy'
              />
            </div>
          )}

          {promotion?.mobile_cover_image_url && (
            <div className='promotion-detail__image promotion-detail__image--mobile'>
              <img
                src={buildCdnUrl(promotion.mobile_cover_image_url, 'LOGOS_CDN_URL')}
                alt=''
                loading='lazy'
              />
            </div>
          )}
          <h2 className='promotion-detail__title'>{promotion?.title}</h2>

          <div
            className='promotion-detail__info'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(promotion?.full_content, {
                KEEP_CONTENT: true,
              }),
            }}
          />
        </div>
      </MainBody>
    </div>
  );
};

export default PromotionPage;
