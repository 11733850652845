import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useApplicationState } from '@apollo/core';
import SignUpForm from '../../shared/components/SignUp/SignUpForm';
import MainBody from '../../shared/components/MainBody/MainBody';
import StaticBlock from '../../shared/components/StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../core/constants';

const SignUp = () => {
  const { push: historyPush } = useHistory();

  const applicationState = useApplicationState();
  const authenticated = _.get(applicationState, 'authenticated');

  useEffect(() => {
    if (authenticated) {
      historyPush('/');
    }
  }, [authenticated]);

  return (
    <MainBody className='main__body--signup'>
      <div className='signup'>
        <div className='signup__container'>
          <div className='b-row'>
            <div className='b-col block left'>
              <SignUpForm />
            </div>
            <div className='b-col block right '>
              <StaticBlock
                options={{ source: cmsStaticBlock.SignUpBanner }}
                className='banner--aside'
              />
              <StaticBlock
                options={{ source: cmsStaticBlock.SignUpBannerRespGambling }}
                className='banner--aside'
              />
            </div>
          </div>
        </div>
      </div>
    </MainBody>
  );
};

export default SignUp;
