import { Link } from 'react-router-dom';
import { RaceTypeAll, RaceTypesMap } from '@apollo/core/src/constants';
import React, { useMemo, useState } from 'react';
import { SiteConfigManager } from '@apollo/core';
import { COMPONENT_TYPES } from '@apollo/routing';
import NextToJumpList from './NextToJumpList';
import { nextToJumpPath } from '../../../Racing/VenuesPage/VenuesPage';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import useNextRaces from '../../../Racing/useNextRaces';

const NextToJumpAll = () => {
  const t = useTranslate();

  const [raceTypes] = useState([RaceTypesMap.HORSE.id, RaceTypesMap.DOG.id, RaceTypesMap.TROT.id]);

  const [nextToJump, nextRacesUpdate] = useNextRaces({
    raceTypes,
    limit: 5,
  });

  const racingPath = useMemo(
    () => SiteConfigManager.getExtraConfig(`pathList.${COMPONENT_TYPES.RACING}`),
    [],
  );
  return (
    <div className='home-next-jump__col home-next-jump__col--all'>
      <div className='home-next-jump__col-title'>
        <span className='race-type'>{t('next_to_jump')}</span>
        <Link to={`${racingPath}${nextToJumpPath}`} className='race-link-all'>
          {t('see_all')}
        </Link>
      </div>
      <NextToJumpList
        nextToJump={nextToJump}
        nextRacesUpdate={nextRacesUpdate}
        raceType={RaceTypeAll.id}
      />
    </div>
  );
};
export default NextToJumpAll;
