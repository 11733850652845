import React from 'react';
import cx from 'classnames';
import Theme from 'themeSource/custom';
import { SportLink } from '../../../shared/components/Sport/SportLinks';

const sportsLink = Theme.DISABLE_SPORTS_DROPDOWN_MOBILE || false;

const SportListItemHeader = ({ className, sportName, toggleExpand, sportUrlName, active, id }) => {
  const classes = cx('sport-list__item-header', `sport-${id}`, className);

  if (sportsLink) {
    return (
      <SportLink sportUrlName={sportUrlName} className={classes}>
        <div>
          <span className={`iconResult sportIcon-${id}`} />
          <span className='name'>{sportName}</span>
          <span className={`arrow AIcon-angle-right ${active ? 'active' : ''}`} />
        </div>
      </SportLink>
    );
  }

  return (
    <div className={classes} onClick={toggleExpand}>
      <div>
        <span className={`iconResult sportIcon-${id}`} />
        <span className='name'>{sportName}</span>
        <span className={`arrow AIcon-angle-right ${active ? 'active' : ''}`} />
      </div>
    </div>
  );
};

export default SportListItemHeader;
