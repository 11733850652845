import React, { useCallback, useMemo, useState } from 'react';
import { map } from 'lodash';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import SportListItem from './SportListItem';

const SportList = ({ isOrderByName = false, sports = SportSelector.selectSports() }) => {
  const [activeSport, setActiveSport] = useState();

  const [sportsState] = useSportState();
  const { tournamentsBySportId } = sportsState;

  const sortedSports = useMemo(
    () => sports.filter((s) => s.totalCount > 0).sort((a, b) => a.name.localeCompare(b.name)),
    [sports],
  );

  const toggleActiveSport = useCallback(
    (id) => {
      if (id === activeSport) {
        setActiveSport(null);
      } else {
        setActiveSport(id);
      }
    },
    [activeSport],
  );

  return map(isOrderByName ? sortedSports : sports, (sport) => {
    const { id } = sport;
    return (
      <SportListItem
        key={id}
        active={activeSport === id}
        onToggleActiveSport={() => toggleActiveSport(id)}
        sport={sport}
        tournaments={tournamentsBySportId[id] || []}
      />
    );
  });
};

export default SportList;
