import React from 'react';
import { map } from 'lodash';
import PaymentMethodItem from './PaymentMethodItem';

const PaymentMethodList = ({ payments, route, type, active = null, children }) => (
  <>
    <div className='payments'>
      {map(payments, ({ id, name, depositType }) => (
        <PaymentMethodItem
          key={id}
          id={id}
          depositType={depositType}
          name={name}
          route={route}
          type={type}
        />
      ))}
    </div>
    {active && children}
  </>
);

export default PaymentMethodList;
