import React, { useEffect, useRef } from 'react';
import { Bus, useApplicationState, useSettings } from '@apollo/core';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Theme from 'themeSource/custom';
import useTranslate from '../I18n/Interpreter';
import { LocalConfig } from '../../../core/utils';
import MainRouter from '../../../core/MainRouter/MainRouter';
import SidebarLeftContainer from '../Sidebar/SidebarLeftContainer';
import SidebarRightContainer from '../Sidebar/SidebarRightContainer';
import { appEvents } from '../../../core/constants';
import MaintenanceAlert from '../MaintenanceAlert/MaintenanceAlert';
import Footer from '../Footer/Footer';
import HeaderContainer from '../Header/HeaderContainer';
import MobileFooterContainer from '../Footer/MobileFooter/MobileFooterContainer';

const Layout = () => {
  const t = useTranslate();
  const { pathname } = useLocation();
  const { layout } = useApplicationState();
  const { debugMode } = useSettings();

  const ref = useRef(null);

  // Dismiss Toast on route changes
  React.useEffect(() => {
    toast.dismiss();
  }, [pathname]);

  React.useEffect(() => {
    if (document) {
      document.title = `${LocalConfig.get('public.title')} ${t('page title')}`;
    }
  }, [t]);

  const resetScrollCallback = () => {
    ref.current?.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    Bus.on(appEvents.resetScroll, resetScrollCallback);
    return () => {
      Bus.off(appEvents.resetScroll, resetScrollCallback);
    };
  }, [ref.current]);

  const mainClasses = cx('main', {
    'main_height-auto': window.location.pathname === '/promotion-tournaments',
    'main--sidebar-left': layout.sideBarLeft === true,
    'main--sidebar-right': layout.sideBarRight === true,
    'main--main-menu': layout.mainMenu === true,
    'main--mobile-device': layout.mobileDevice === true,
    'debug-mode': debugMode,
  });

  const ThemeHeaderContainer = Theme.HeaderContainer || HeaderContainer;

  return (
    <>
      <ThemeHeaderContainer />
      <main className={mainClasses}>
        <SidebarLeftContainer />
        <div ref={ref} className='main__page'>
          <div className='main__page__content'>
            <MaintenanceAlert />
            <MainRouter />
          </div>
          {layout.mobileDevice ? <MobileFooterContainer /> : <Footer />}
        </div>
        <SidebarRightContainer />
      </main>
    </>
  );
};

export default Layout;
