import React, { useEffect, useState } from 'react';
import RaceRunner from './RaceRunner';
import RunnerOddsHistories from './RunnerOddsHistories';
import RunnerStatic from './runnerStatic/RunnerStatic';
import SpeedMapItem from './runnerStatic/SpeedMapItem';

const RaceRunnerRow = ({
  runner,
  runnerStatic,
  activeRace,
  isFlucs,
  isForm,
  isSpeedMap,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(isForm);

  useEffect(() => {
    setIsExpanded(isForm);
  }, [isForm]);

  return (
    <div
      key={runner.runnerId}
      className={`event event--classic ${
        runner.scratched ? 'event--scratched' : ''
      } ${isExpanded && !runner.scratched ? 'expanded' : ''}`}
    >
      <div
        className={`event__main-period ${runnerStatic && !runner.scratched ? 'collapsible' : ''}`}
        onClick={() => !runner.scratched && setIsExpanded(runnerStatic && !isExpanded)}
      >
        <RaceRunner runner={runner} activeRace={activeRace} showRunnerImage={!runner.scratched} />
        {children}
      </div>
      {isFlucs ? <RunnerOddsHistories activeRace={activeRace} runner={runner} /> : null}

      {runnerStatic && isSpeedMap ? (
        <div className='speed-map'>
          <SpeedMapItem runnerStatic={runnerStatic} />
        </div>
      ) : null}

      {runnerStatic && isExpanded ? <RunnerStatic runnerStatic={runnerStatic} /> : null}
    </div>
  );
};

export default RaceRunnerRow;
