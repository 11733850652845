import React from 'react';
import cx from 'classnames';
import useTranslate from '../I18n/Interpreter';
import { AnimatedDropdown } from '../../../features/Animations/AnimatedDropdown';
import Label from '../I18n/Label';

const BonusBannerItem = ({ bonus, isOpen = true, classes }) => {
  const t = useTranslate();

  const [isBannerExpanded, setIsBannersExpanded] = React.useState(isOpen);

  const handleExpandBanner = () => {
    setIsBannersExpanded(!isBannerExpanded);
  };

  const classNames = cx('bet-return-bonus-banner', classes);

  return (
    <div className={classNames}>
      <div className='bet-return-bonus-banner--title'>
        <h3>
          <i className='icons icon--bet-bonus-all' title={t(bonus.bonusType)} />
          {bonus.name}
        </h3>
        <div
          className='bet-return-bonus-banner__toggler'
          onClick={() => {
            handleExpandBanner();
          }}
        >
          {isBannerExpanded ? <Label message='less' /> : <Label message='more' />}
        </div>
      </div>
      <AnimatedDropdown
        isExpanded={isBannerExpanded}
        className='bet-return-bonus-banner--description accordion-body show'
      >
        {bonus.description}
      </AnimatedDropdown>
    </div>
  );
};

export default BonusBannerItem;
