import React, { useCallback } from 'react';
import { get, isNull } from 'lodash';
import Input from '../../Input/Input';
import { getIsFieldRequired } from '../../../../../core/utils';

const TextField = ({ field, onChange, onFocus, onBlur, ...restProps }) => {
  const {
    type,
    touched,
    value,
    autofocus,
    disabled = false,
    readOnly = false,
    warnings,
    active,
    label,
    placeholder,
    name,
    rules,
    errors,
    className,
    uiTitleClassName,
    amountIcon,
  } = field;

  const inputValue = isNull(value) ? '' : value;
  const changeHandler = useCallback((event) => onChange(event.target.value), [onChange]);
  const error = touched && errors[0] ? errors[0] : '';
  const success = touched && !errors[0];
  const warning = touched ? get(warnings, '[0].message') : null;

  return (
    <Input
      type={type || 'text'}
      name={name}
      error={error}
      warning={warning}
      value={inputValue}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={changeHandler}
      autoFocus={autofocus}
      touched={touched}
      active={active}
      disabled={disabled || false}
      readOnly={readOnly || false}
      label={label}
      isRequiredSymbol={getIsFieldRequired(rules)}
      success={success}
      className={className}
      uiTitleClassName={uiTitleClassName}
      amountIcon={amountIcon}
      withTranslate
      {...restProps}
    />
  );
};

export default TextField;
