import { toast } from 'react-toastify';

class ToastManager {
  static showNotification(type, message) {
    toast.dismiss();
    let method;
    if (type === 'info') {
      method = toast.info;
    } else if (type === 'error') {
      method = toast.error;
    } else if (type === 'success') {
      method = toast.success;
    } else if (type === 'warning') {
      method = toast.warn;
    } else {
      method = toast;
    }

    return method(message);
  }

  static notify(...props) {
    return this.showNotification('default', ...props);
  }

  static info(...props) {
    return this.showNotification('info', ...props);
  }

  static error(...props) {
    return this.showNotification('error', ...props);
  }

  static success(...props) {
    return this.showNotification('success', ...props);
  }

  static warning(...props) {
    return this.showNotification('warning', ...props);
  }
}

export default ToastManager;
