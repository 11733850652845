import React, { useEffect, useState } from 'react';
import { CmsApi, useApplicationState, useSettings } from '@apollo/core';
import { isEmpty } from 'lodash';
import useTranslate from '../../shared/components/I18n/Interpreter';
import MainBody from '../../shared/components/MainBody/MainBody';
import Label from '../../shared/components/I18n/Label';
import PromotionItem from './PromotionItem';
import CmdLink from '../../shared/components/CmdLink';
import SignUpLink from '../../shared/components/SignUp/SignUpLink';

const PromotionsListPage = () => {
  const t = useTranslate();
  const { lang } = useSettings();
  const { authenticated } = useApplicationState();

  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    const ac = new AbortController();
    CmsApi.getPromotionsList({ signal: ac.signal }).then((payload) => {
      setPromotions(payload);
    });
    return () => ac.abort();
  }, [lang, authenticated]);

  return (
    <div className='main__page main__page--promotions'>
      <MainBody className='main__body--promotions'>
        <div className='promotions'>
          <div className='sport-page__header sport-page__header--promotions'>
            <h1 className='sport-name'>{t('promotions')}</h1>
          </div>

          {!isEmpty(promotions) ? (
            <div className='promotions__list'>
              {promotions.map((promotion) => (
                <PromotionItem key={promotion?.id} promotion={promotion} />
              ))}
            </div>
          ) : (
            <div className='no-promotions'>
              <h3 className='no-promotions__title'>
                <Label message='no_promotions_available' />
              </h3>

              <Label message='check_promotions_later' />

              {!authenticated ? (
                <div className='no-promotions__signin'>
                  <CmdLink className='btn btn-login' cmd='signin'>
                    <Label message='login' />
                  </CmdLink>
                  <SignUpLink />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </MainBody>
    </div>
  );
};

export default PromotionsListPage;
