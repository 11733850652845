import React from 'react';
import { SportLink } from '../../../../shared/components/Sport/SportLinks';

const SportTypeButton = ({ sport }) => (
  <div className={`type__container type__container--${sport.id}`}>
    <SportLink className='race-link-all ' sportUrlName={sport.urlName}>
      <div className='type__container-inner'>
        <div className='type__item-icon-blank' />
        <div className='type__item-group-icon'>
          <i className={`icon sportIcon-${sport.id}`} title={sport.name} />
        </div>
      </div>

      <div className='type__title'>
        <div>
          <span>{sport.name}</span>
        </div>
      </div>
    </SportLink>
  </div>
);
export default SportTypeButton;
