import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './FormStepsIndicator.scss';

const FormStepsIndicator = ({ steps, activeStep }) => (steps && steps.length > 1 ? (
  <div className='form-steps-indicator'>
    {steps.map((step) => (
      <div key={`step-${step.number}`} className='form-steps-indicator__step-container'>
        <div
          className={cx('form-steps-indicator__circle', {
            'form-steps-indicator__circle--active': step.number <= activeStep,
          })}
        >
          {step.number + 1}
        </div>
        {step.number < steps.length - 1 && <div className='form-steps-indicator__line' />}
      </div>
    ))}
  </div>
) : null);

FormStepsIndicator.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default FormStepsIndicator;
