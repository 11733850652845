import React, { useCallback, useEffect, useState } from 'react';
import { CmsApi, useSettings } from '@apollo/core';
import DOMPurify from 'dompurify';
import useTranslate from '../../shared/components/I18n/Interpreter';
import Loader from '../../shared/components/Loader/Loader';
import MainBody from '../../shared/components/MainBody/MainBody';

const defaultState = {
  content: null,
  error: null,
  resolved: false,
  loading: true,
};

const StaticPage = ({ match: { params: { slug } } }) => {
  const t = useTranslate();
  const [state, setState] = useState(defaultState);
  const { lang } = useSettings();
  const handleStaticDataLoaded = useCallback((res) => {
    setState((state) => ({
      ...state,
      content: res.data,
      loading: false,
      resolved: true,
    }));
  }, [setState]);

  const handleStaticDataError = useCallback((staticError) => {
    const { error } = staticError;

    setState((state) => ({
      ...state,
      error,
      loading: false,
      resolved: true,
    }));
  }, [setState]);

  const makeContent = (content) => ({
    __html: DOMPurify.sanitize(content, { KEEP_CONTENT: true }),
  });

  useEffect(() => {
    setState(defaultState);
    const ac = new AbortController();
    CmsApi.getPage({
      source: slug,
      lang,
      signal: ac.signal,
    })
      .then(handleStaticDataLoaded)
      .catch(handleStaticDataError);
    return () => ac.abort();
  }, [lang, slug, handleStaticDataError, handleStaticDataLoaded]);

  const renderStaticContent = (content) => (
    <div
      /* eslint-disable-next-line react/no-danger */
      dangerouslySetInnerHTML={makeContent(content)}
    />
  );

  const renderView = () => {
    const {
      content,
      error,
      loading,
      resolved,
    } = state;

    if (loading) {
      return (
        <div>
          <Loader />
          <div className='loading'>{t('loading...')}</div>
        </div>
      );
    }

    if (resolved) {
      if (error) {
        return <div>{error.message}</div>;
      }

      if (content) {
        return renderStaticContent(content);
      }
    }

    return null;
  };

  return (
    <MainBody className='main__body--static-page'>
      <div className='static_page'>
        <div className={slug}>
          {renderView()}
        </div>
      </div>
    </MainBody>
  );
};

export default StaticPage;
