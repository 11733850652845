import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { BONUS_TYPE } from '@apollo/core/src/constants';
import { centsToDecimal, useApplicationState } from '@apollo/core';
import { at } from 'lodash';
import Label from '../../../../../shared/components/I18n/Label';
import RaceCountdown from '../../../../../shared/components/Racing/RaceCountdown/RaceCountdown';
import { splitHandler } from '../../../../../shared/modals/Bonus/BonusModal';
import { getCurrencySymbol } from '../../../../../core/utils';
import BonusBetTracker from './BonusBetTracker';
import BonusTurnoverTracker from './BonusTurnoverTracker';

const BonusBannerItem = ({ bonus }) => {
  const applicationState = useApplicationState();
  const [currency] = at(applicationState, ['wallet.currency']);

  const currencySymbol = getCurrencySymbol(currency);

  const hasTrackers = useMemo(() => {
    if (!bonus?.props) {
      return false;
    }
    const { requiredBetsCount = 0, requiredTurnover = 0 } = bonus.props;
    return requiredBetsCount > 0 || requiredTurnover > 0;
  }, [bonus.props]);

  const confirmationHandler = useCallback(
    (bonus) => {
      splitHandler(bonus, currencySymbol);
    },
    [currencySymbol]
  );

  const classes = cx(
    'bonus-banners__item',
    `${bonus.props?.service ?? ''}`,
    `${bonus.bonusType}`,
    hasTrackers ? 'bonus-banners__item--trackers' : null
  );

  return (
    <div key={bonus.id} className={classes}>
      <Label message={bonus.bonusType} className='bonus-type' />
      <div className='bonus-name'>{bonus.props?.name}</div>
      <div className='bonus-description'>{bonus.props?.description}</div>
      {bonus.bonusType === BONUS_TYPE.FREEBET && (
        <div
          className={cx('bonus-amount', { active: bonus?.props?.canSplit })}
          onClick={() => (bonus?.props?.canSplit ? confirmationHandler(bonus) : {})}
        >
          {currencySymbol} {centsToDecimal(bonus.freeBetAmount)}
        </div>
      )}
      <div className='bonus-timer'>
        {bonus.props?.expiry ? (
          <>
            <Label message='expires in' />

            <RaceCountdown date={new Date(bonus.props?.expiry)} />
          </>
        ) : null}

        {bonus?.props?.canSplit && (
          <button
            className='btn btn-sm btn-primary-outline btn-bonus-split'
            type='button'
            onClick={() => confirmationHandler(bonus)}
          >
            <Label message='split' />
          </button>
        )}
      </div>

      {hasTrackers && (
        <div className='bonus-banners__item-trackers'>
          <BonusBetTracker eligibilityStats={bonus.props} />
          <BonusTurnoverTracker eligibilityStats={bonus.props} />
        </div>
      )}

      <div className='bonus-service'>{bonus.props?.service}</div>
    </div>
  );
};

export default React.memo(BonusBannerItem);
