import { RaceTypesMap } from '@apollo/core/src/constants';
import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';
import RaceTypeButton from './RaceTypeButton';
import RaceLink from '../../../../shared/components/Racing/RaceLink';
import NextToJumpList from './NextToJumpList';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import useNextRaces from '../../../Racing/useNextRaces';

const NextToJumpType = ({ raceType, raceDate }) => {
  const t = useTranslate();

  const raceTypeName = RaceTypesMap[raceType]?.typeName;

  const raceTypes = useMemo(() => [RaceTypesMap[raceType]?.id], [raceType]); // to prevent recursive call

  const [nextToJump, nextRacesUpdate] = useNextRaces({
    raceTypes,
    limit: 5,
  });

  return (
    <div
      className={`home-next-jump__col home-next-jump__col--${raceType.toLowerCase()} ${isEmpty(nextToJump) ? 'home-next-jump__col--empty' : ''}`}
      key={raceType}
    >
      {/*
      <div className='types types--racing'>
        <RaceTypeButton raceType={raceType} raceDate={raceDate} />
      </div>
      */}
      <div className='home-next-jump__col-box'>
        <div className='home-next-jump__col-title'>
          <span className='race-next-to-jump'>{t('next_to_jump')}</span>
          <span className='race-type'>{raceTypeName}</span>
          <RaceLink className='race-link-all' raceDate={raceDate} raceType={raceTypeName}>
            {t('see_all')}
          </RaceLink>
        </div>
        <NextToJumpList
          nextToJump={nextToJump}
          nextRacesUpdate={nextRacesUpdate}
          raceType={RaceTypesMap[raceType]?.id}
        />
      </div>
    </div>
  );
};
export default NextToJumpType;
