import React from 'react';
import { SettingsManager, useSettings } from '@apollo/core';
import ToggleCheckbox from '../../../shared/components/ToggleCheckbox/ToggleCheckbox';
import Label from '../../../shared/components/I18n/Label';

const QuickBetSettings = ({ disabled = false }) => {
  const { quickBet } = useSettings();

  const handleToggleQuickBet = React.useCallback(() => {
    SettingsManager.updateSettings({ quickBet: !quickBet });
  }, [quickBet]);

  return (
    <div className='settings-content-controls'>
      <div className='settings-content-controls__title'>
        <Label message='betslip' />
      </div>

      <div className='settings-content-controls__input settings-content-controls__input--checkbox'>
        <div className='container'>
          <div className='switchBlockInfo'>
            <Label message='turn_quick_bet_on' />
          </div>
          <ToggleCheckbox
            disabled={disabled}
            checked={quickBet}
            onChangedHandler={handleToggleQuickBet}
          />
        </div>
        <div className='settings-content-controls__hint'>
          <Label message='turn_quick_bet_on_description' />
        </div>
      </div>
    </div>
  );
};

export default QuickBetSettings;
