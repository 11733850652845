import React from 'react';
import { constants, SettingsManager, useSettings } from '@apollo/core';
import Label from '../../../shared/components/I18n/Label';

const { YES, UP, NO } = constants.ACCEPT_ODDS_CHANGES_TYPES;

const AcceptOddsChangesOptions = {
  // [YES]: 'Always accept',
  [UP]: 'Accept ascending only',
  [NO]: 'Always ask',
  // [DOWN]: 'Accept descending only',
};

const AcceptOddsChangesKeys = Object.keys(AcceptOddsChangesOptions);

const AcceptOddsSettings = () => {
  const {
    acceptOddsChanges,
  } = useSettings();

  const handleSelect = (option) => {
    SettingsManager.updateSettings({
      acceptOddsChanges: option,
    });
  };

  const OptionComponents = React.useMemo(
    () => AcceptOddsChangesKeys.map((option) => {
      const message = AcceptOddsChangesOptions[option];
      const checked = (acceptOddsChanges === option);

      return (
        <label key={option} className='container radio-container' onClick={() => handleSelect(option)}>
          <Label message={message} />
          <input type='radio' checked={checked} name={option} />
          <span className='checkmark' />
        </label>
      );
    }),
    [acceptOddsChanges],
  );

  return (
    <div className='settings-content-controls'>
      <div className='settings-content-controls__title'>
        <Label message='accept_odds_changes' />
      </div>

      <div className='settings-content-controls__input settings-content-controls__input--radio'>
        {OptionComponents}
      </div>

    </div>
  );
};
export default AcceptOddsSettings;
