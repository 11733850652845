import React, { useEffect, useMemo } from 'react';
import Label from '../../components/I18n/Label';
import AuthBlock from '../AuthBlock/AuthBlock';
import StaticBlock from '../../components/StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../core/constants';
import useTranslate from '../../components/I18n/Interpreter';

const Version = () => {
  const t = useTranslate();
  return (
    <AuthBlock allowClose={false}>
      <div className='b-row'>
        <div className='auth-block-modal__form b-col'>
          <header className='auth-block-modal__container__header'>
            <h1 className='auth-block-modal__container__header__title'>
              <Label message='version_block' />
            </h1>
          </header>
          <div className='auth-block-modal__form__top'>
            <div className='auth-block-modal__form__top__inputs stretch'>
              <div className='block'>
                <StaticBlock options={{ source: cmsStaticBlock.VersionBlock }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthBlock>
  );
};

export default Version;
