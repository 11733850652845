import React from 'react';

import { NotificationManager } from '@apollo/core';

import { isEmpty } from 'lodash';
import RegistrationBonusPopup from '../../popups/RegistrationBonusPopup/RegistrationBonusPopup';
import NotificationsPortal from './NotificationsPortal';
import ConfirmPopup from '../../popups/ConfirmPopup';
import InformationPopup from '../../popups/InformationPopup';
import BaseNotification from '../../popups/BaseNotification';
import RealityCheckPopup from '../../popups/RealityCheckPopup';
import OneClickSignUp from '../../popups/OneClickSignUp';
import DisconnectionPopup from '../../popups/DisconnectionPopup';
import BitcoinPopup from '../../popups/BitcoinPopup';
import TemplatePopup from '../../popups/TemplatePopup';

// NOTIFICATION_TYPE.[key]
const NotificationsComponentsMap = {
  confirm: ConfirmPopup,
  notification: BaseNotification,
  message: BaseNotification,
  popup: InformationPopup,
  realityCheck: RealityCheckPopup,
  oneClickSignUp: OneClickSignUp,
  disconnect: DisconnectionPopup,
  // registrationBonus: RegistrationBonusPopup,
  bitcoin: BitcoinPopup,
  templatePopup: TemplatePopup,
};

const notificationsReducer = (state, action) => {
  switch (action.type) {
    case 'ADD':
      if (state.find((notification) => notification.id === action.payload.id)) {
        return state;
      }

      return [...state, action.payload];
    case 'REMOVE':
      return state.reduce((acc, notification) => {
        if (notification.id === action.payload) {
          return acc;
        }

        return [...acc, notification];
      }, []);
    default:
      return state;
  }
};

const initialState = [];

const NotificationsContainer = (props) => {
  const [notifications, dispatch] = React.useReducer(notificationsReducer, initialState);

  const actionHandler = (action) => {
    dispatch(action);

    // Returning notifications length
    return notifications.length;
  };

  React.useEffect(() => {
    NotificationManager.subscribe(actionHandler);

    return () => {
      NotificationManager.unsubscribe(actionHandler);
    };
  }, [notifications, dispatch]);

  const NotificationsComponents = React.useMemo(
    () => notifications.map((notificationData) => {
      const { type, ...notificationPayload } = notificationData;

      const NotificationComponent = NotificationsComponentsMap[type];

      if (!NotificationComponent) {
        return null;
      }

      return <NotificationComponent key={notificationData.id} {...notificationPayload} />;
    }),
    [notifications],
  );

  if (isEmpty(NotificationsComponents)) {
    return null;
  }

  return (
    <NotificationsPortal className='notifications-container'>
      {NotificationsComponents}
    </NotificationsPortal>
  );
};
export default NotificationsContainer;
