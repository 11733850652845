import React, { useEffect } from 'react';
import { Bus, DedicatedEventProvider, isFeedOffline, useBetslipState } from '@apollo/core';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import DetailPageEvent from './DetailPageEvent';
import Label from '../../../shared/components/I18n/Label';
import { SgmProvider } from '../../../state/Sgm/SgmState';
import SportLoader from '../SportLoader';

const DetailsPage = () => {
  const [sportsState] = useSportState();
  const { selectedEventId, loading } = sportsState;
  const selectedEvent = SportSelector.selectCurrentEvent();

  const {
    settings: { feedStates },
  } = useBetslipState();

  useEffect(() => {
    if (selectedEventId) {
      Bus.emit(Bus.events.sport.sportLoadSingleEvent, {
        eventId: selectedEventId,
        marketGroup: 'MAIN',
      });
    }
  }, [selectedEventId]);

  if (!selectedEvent) {
    if (loading) {
      return <SportLoader />;
    }
    return null;
  }

  // todo: I don't think this will work. We'll need a better "Event not found" system.
  if (selectedEvent?.error) {
    return (
      <div className='sportDetails animate-fade-left-in'>
        <Label message='event_not_found' />
      </div>
    );
  }

  const isEventFeedOffline = isFeedOffline(feedStates[selectedEvent.source]);

  return (
    <DedicatedEventProvider event={selectedEvent}>
      <SgmProvider>
        <DetailPageEvent event={selectedEvent} suspended={isEventFeedOffline} />
      </SgmProvider>
    </DedicatedEventProvider>
  );
};

export default DetailsPage;
