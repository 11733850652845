import React, { useMemo } from 'react';
import { isEmpty, map } from 'lodash';
import Scrollbars from 'react-custom-scrollbars';
import { DedicatedEventProvider, isHidden } from '@apollo/core';
import { SPORT_SERVICE } from '@apollo/core/src/constants';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import TopEvent from '../../../Sports/TopEvents/TopEvent/TopEvent';

const SportEvents = ({ title, events }) => {
  const filteredEvents = useMemo(
    () => events?.filter((e) => {
      if (e.sportService !== SPORT_SERVICE.PREMATCH) {
        return false;
      }
      if (isHidden(e) || !hasMarketTypes(e)) {
        return false;
      }
      return true;
    }) || [],
    [events],
  );

  if (isEmpty(filteredEvents)) {
    return null;
  }

  return (
    <div className='sport-events'>
      {!isEmpty(title) && <h3 className='sport-events__title'>{title}</h3>}
      <div className='sport-events__list'>
        <Scrollbars className='scroll__container' autoHeight>
          {map(filteredEvents, (event) => (
            <DedicatedEventProvider event={event} key={event.id}>
              <TopEvent className='sport-events__list-item' />
            </DedicatedEventProvider>
          ))}
        </Scrollbars>
      </div>
    </div>
  );
};
export default SportEvents;
