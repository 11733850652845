import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { SportsActions, useSportState } from '@apollo/core/src/state/sport/sport';
import { useLocation } from 'react-router-dom';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import AllSports from './AllSport';
import Upcoming from './Upcoming';
import MainBody from '../../../shared/components/MainBody/MainBody';
import { SportLink, SPORTS_TABS } from '../../../shared/components/Sport/SportLinks';
import Label from '../../../shared/components/I18n/Label';

export const tabs = {
  ALL_SPORTS: 'ALL_SPORTS',
  UPCOMING: 'UPCOMING',
  BET_LIVE: 'BET_LIVE',
};

const getTabsHashPath = (tab) => {
  switch (tab) {
    case tabs.ALL_SPORTS:
      return SPORTS_TABS.ALL_SPORTS;
    case tabs.UPCOMING:
      return SPORTS_TABS.UPCOMING_SPORTS;
    default:
      return SPORTS_TABS.ALL_SPORTS;
  }
};

const SportPage = () => {
  const t = useTranslate();
  const [activeTab, setActiveTab] = useState(tabs.ALL_SPORTS);
  const [, sportsDispatcher] = useSportState();

  const { hash } = useLocation();

  const topMenuItems = useMemo(
    () => [
      {
        name: t('all_sports'),
        id: tabs.ALL_SPORTS,
      },
      {
        name: t('upcoming'),
        id: tabs.UPCOMING,
      },
      /*  {
        name: t('bet_live'),
        id: tabs.BET_LIVE,
        onClick: (id) => setActiveTab(id),
      }, */
    ],
    [t],
  );

  useEffect(() => {
    switch (hash?.replace('#', '')) {
      case SPORTS_TABS.ALL_SPORTS:
        setActiveTab(tabs.ALL_SPORTS);
        break;
      case SPORTS_TABS.UPCOMING_SPORTS:
        setActiveTab(tabs.UPCOMING);
        break;
      default:
        setActiveTab(tabs.ALL_SPORTS);
        break;
    }
  }, [hash]);

  useEffect(() => {
    sportsDispatcher({
      type: SportsActions.SPORT_STATE_UPDATE,
      payload: {
        selectedSportId: null,
        selectedCategoryId: null,
        selectedTournamentId: null,
        selectedEventId: null,
        selectedSportUrlName: null,
        selectedCategoryUrlName: null,
        selectedTournamentUrlName: null,
        selectedEventUrlName: null,
      },
    });
  }, []);

  return (
    <div className='main__body--sport-page'>
      <div className='sport-page__header'>
        <h1 className='sport-name'>
          <Label message='all sports' />
        </h1>
        <div className='tournament__header'>
          <div className='tournament__header__market-row'>
            {topMenuItems.map(({ name, id }) => (
              <SportLink
                key={id}
                hash={getTabsHashPath(id)}
                className={cx(['sport-filter-option', activeTab === id && 'active'])}
              >
                {name}
              </SportLink>
            ))}
          </div>
        </div>
      </div>
      {activeTab === tabs.ALL_SPORTS && <AllSports />}
      {activeTab === tabs.UPCOMING && <Upcoming />}
    </div>
  );
};

export default SportPage;
