import React from 'react';
import { Link } from 'react-router-dom';
import { staticRoutes } from '@apollo/routing';
import { THEME_NAMES } from '../../../core/constants';
import CmdLink from '../CmdLink';
import Label from '../I18n/Label';
import { LocalConfig } from '../../../core/utils';

const { signup } = staticRoutes;

const SignUpLink = ({ onClickHandler, className = 'btn btn-registration' }) => {
  const themeName = LocalConfig.get('THEME_NAME')?.toLowerCase();

  // TODO: use config
  return [
    THEME_NAMES.betKings,
    THEME_NAMES.betMax,
    THEME_NAMES.MGMBet,
    THEME_NAMES.betPlay,
    THEME_NAMES.favBet,
    THEME_NAMES.winners,
    THEME_NAMES['123Bet'],
  ].includes(themeName) ? (
    <CmdLink className={className} cmd='signup' onClick={onClickHandler}>
      <Label message='registration' />
    </CmdLink>
  ) : (
    <Link className={className} to={signup} onClick={onClickHandler}>
      <Label message='registration' />
    </Link>
  );
};

export default SignUpLink;
