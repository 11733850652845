import React, { useEffect, useRef, useState } from 'react';
import { Bus, useApplicationState, useBetslipState } from '@apollo/core';
import classNames from 'classnames';
import Label from '../../../I18n/Label';

const ANIMATION_TIMEOUT_MS = 600;

const BetSlipButton = () => {
  const preCountRef = useRef(0);
  const { layout } = useApplicationState();
  const { betItems } = useBetslipState();
  const [directionClassName, setDirectionClassName] = useState(null);
  const resetDirectionClassTimerRef = useRef(null);

  useEffect(() => {
    const prevCount = preCountRef.current;

    const newCount = _.toNumber(betItems.length);
    const dOdds = newCount - prevCount;

    setDirectionClassName(dOdds > 0 ? 'up' : 'down');
    resetDirectionClassTimerRef.current = setTimeout(() => {
      preCountRef.current = newCount;
      setDirectionClassName(null);
    }, ANIMATION_TIMEOUT_MS);

    if (!layout.mobileDevice && dOdds > 0) {
      Bus.send({
        event: Bus.events.layout.setSideBarRight,
        data: true,
      });
    }
  }, [betItems?.length, layout.mobileDevice]);

  const toggleBetSlip = React.useCallback(() => {
    Bus.send({
      event: Bus.events.layout.setSideBarRight,
      data: !layout.sideBarRight,
    });
  }, [Bus, layout]);

  return (
    <button
      type='button'
      className='btn btn-betslip-show'
      onClick={toggleBetSlip}
    >
      <Label message='betslip' />
      <div className='badge__container'>
        <div
          className={classNames('stripe', {
            animate: directionClassName,
            increasing: directionClassName === 'up',
            decreasing: directionClassName === 'down',
          })}
        >
          {!directionClassName ? (
            <span className={classNames('badge')}>
              {betItems?.length ? betItems.length - 1 : 0}
            </span>
          ) : (
            <span className={classNames('badge')}>
              {preCountRef.current - 1 ? preCountRef.current - 1 : 0}
            </span>
          )}
          {!directionClassName ? (
            <span className={classNames('badge')}>
              {betItems ? betItems.length : 0}
            </span>
          ) : (
            <span className={classNames('badge')}>
              {preCountRef.current ? preCountRef.current : 0}
            </span>
          )}
          {!directionClassName ? (
            <span className={classNames('badge')}>
              {betItems?.length ? betItems.length + 1 : 0}
            </span>
          ) : (
            <span className={classNames('badge')}>
              {preCountRef.current + 1 ? preCountRef.current + 1 : 0}
            </span>
          )}
        </div>
      </div>
    </button>
  );
};

export default React.memo(BetSlipButton);
