import React, { useEffect, useMemo } from 'react';
import { Bus, isHidden } from '@apollo/core';
import { chain, isEmpty } from 'lodash';
import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import useHasTournamentOnlyOutrightEvents from './useHasTournamentOnlyOutrightEvents';
import TournamentHeader from './TournamentHeader/TournamentHeader';
import EventList from './EventDateGroup/EventList';
import useEventListTemplate from './EventDateGroup/useEventListTemplate';
import { EVENT_LIST_TEMPLATE } from '../../../core/utils';
import SportLoader from '../SportLoader';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import Label from '../../../shared/components/I18n/Label';

const ITEMS_PER_PAGE = 100;

const Tournament = ({
  groupEventsByDate,
  mainMarketTypesByPeriod,
  isFavoriteList,
  excludeOutright = false,
}) => {
  const t = useTranslate();
  const [sportsState, sportsDispatcher] = useSportState();
  const { loading, selectedTournamentId } = sportsState;

  const [eventListTemplate, setListTemplate] = useEventListTemplate();
  const [activeMarketTypeId, setActiveMarketTypeId] = React.useState();

  const events = SportSelector.selectEventsByTournamentId(selectedTournamentId);

  const filteredEvents = useMemo(
    () =>
      events.filter((e) => {
        if (isHidden(e) || !hasMarketTypes(e)) {
          return false;
        }
        if (excludeOutright && e.outright) {
          return false;
        }
        return true;
      }),
    [selectedTournamentId, events, excludeOutright]
  );

  const filterClickHandler = React.useCallback(
    (id) => {
      const template = id === 'outright' ? EVENT_LIST_TEMPLATE.OUTRIGHT : '';
      setListTemplate(template);
      setActiveMarketTypeId(id);
    },
    [setListTemplate, setActiveMarketTypeId]
  );

  const hasOnlyOutrightEvents = useHasTournamentOnlyOutrightEvents(events);

  const mainMarketTypeHeadings = React.useMemo(
    () => [
      // Add Market Type Tabs
      ...chain(mainMarketTypesByPeriod)
        .get('fullPeriod.marketTypes')
        .filter(
          ({ id, show, providerName }) =>
            show &&
            // if exists any event with the market.
            filteredEvents.some((event) => event.marketTypes[id])
        )
        .orderBy('mainOrderPosition')
        .map(({ id, name }) => ({ id, name, onClick: filterClickHandler }))
        .value(),

      // Add Outright/Feature Tab
      ...(chain(filteredEvents) // add feature tab if we have outright
        .filter(({ outright, source }) => outright)
        .value().length
        ? [{ id: 'outright', name: t('future'), onClick: filterClickHandler }]
        : []),
    ],
    [mainMarketTypesByPeriod, filteredEvents]
  );

  useEffect(() => {
    if (!selectedTournamentId) {
      return;
    }
    Bus.emit(Bus.events.sport.sportLoadEvents, {
      filter: {
        isOnlyMainMarketTypes: true,
        fromDate: Date.now(),
        toDate: null,
        tournamentIds: [selectedTournamentId],
      },
      paging: {
        itemsPerPage: ITEMS_PER_PAGE,
        currentPage: 0,
      },
    });
  }, [selectedTournamentId]);

  useEffect(() => {
    if (activeMarketTypeId === undefined && mainMarketTypeHeadings[0]) {
      setActiveMarketTypeId(mainMarketTypeHeadings[0].id);
    }
  }, [mainMarketTypeHeadings]);

  const renderHeader = React.useMemo(() => {
    if (hasOnlyOutrightEvents) {
      return null;
    }

    return (
      <TournamentHeader
        filterItems={mainMarketTypeHeadings}
        activeItemId={activeMarketTypeId}
        hide={eventListTemplate === EVENT_LIST_TEMPLATE.MULTI_MARKETS}
      />
    );
  }, [hasOnlyOutrightEvents, activeMarketTypeId, eventListTemplate, mainMarketTypeHeadings]);

  if (isEmpty(filteredEvents) && (loading || !selectedTournamentId)) {
    return <SportLoader />;
  }

  if (isEmpty(filteredEvents)) {
    return (
      <h3 className='nothing-found'>
        <Label message='nothing_found' />
      </h3>
    );
  }

  return (
    <div className='tournament tournament--classic'>
      {renderHeader}
      <EventList
        events={filteredEvents}
        activeMarketTypeId={activeMarketTypeId}
        groupByDate={groupEventsByDate}
        isFavoriteList={isFavoriteList}
        template={eventListTemplate}
        mainMarketTypesByPeriod={mainMarketTypesByPeriod}
      />
    </div>
  );
};

export default Tournament;
