import { RaceTypesMap } from '@apollo/core/src/constants';
import React, { useMemo } from 'react';
import { SiteConfigManager } from '@apollo/core';
import { COMPONENT_TYPES } from '@apollo/routing';
import RaceTypeButton from './RaceTypeButton';
import { isPromotionsActive } from '../../../../core/utils';
import GenericTypeButton from '../GenericTypeButton';

const RaceTypeButtonList = ({ raceDate }) => {
  const promotionsRoute = SiteConfigManager.getComponentPath(COMPONENT_TYPES.PROMOTIONS);
  const promotionsActive = useMemo(() => isPromotionsActive(), [promotionsRoute]);
  return (
    <div className='home-next-jump home-next-jump--mobile'>
      <div className='home-next-jump_racing'>
        <div className='types types--racing'>
          {promotionsActive && (
            <GenericTypeButton
              path={promotionsRoute}
              label='promotions'
              icon={<i className='icons icon--bet-bonus-all' />}
            />
          )}
          <RaceTypeButton raceType={RaceTypesMap.HORSE.id} raceDate={raceDate} />
          <RaceTypeButton raceType={RaceTypesMap.DOG.id} raceDate={raceDate} />
          <RaceTypeButton raceType={RaceTypesMap.TROT.id} raceDate={raceDate} />
        </div>
      </div>
    </div>
  );
};
export default RaceTypeButtonList;
