import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const AnimatedDropdown = ({ isExpanded, className, children, onClick }) => (
  <AnimatePresence>
    {isExpanded && (
      <motion.div
        className={className}
        initial={{ height: 0 }}
        animate={{ height: 'auto' }}
        exit={{ height: 0 }}
        transition={{ duration: 0.3 }}
        onClick={onClick}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);
