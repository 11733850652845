import React, { useCallback, useEffect, useRef, useState } from 'react';
import { map } from 'lodash';
import { Bus, useApplicationState } from '@apollo/core';
import Scrollbars from 'react-custom-scrollbars';
import { useLocation } from 'react-router-dom';
import useTranslate from '../../shared/components/I18n/Interpreter';
import LinkListItem from './LinkListItem';
import { BonusSelector } from '../../state/Bonus/Bonus';

const LinkList = ({ array, parentPath, itemClassName, isScroll = true, scrollIntoView }) => {
  const t = useTranslate();
  const [activeItem, setActiveItemItem] = useState(array[0]?.name);
  const { layout } = useApplicationState();
  const hasActiveDepositBonus = BonusSelector.hasActiveDepositBonus();
  const activeItemRef = useRef();

  const { pathname } = useLocation();

  useEffect(() => {
    const { current } = activeItemRef;

    if (current && scrollIntoView) {
      current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  }, [scrollIntoView, pathname]);

  const onClickHandler = useCallback(
    (name) => {
      if (layout.mobileDevice) {
        Bus.send({
          event: Bus.events.layout.setSideBarLeft,
          data: false,
        });
      }

      setActiveItemItem(name);
    },
    [setActiveItemItem, layout]
  );

  const renderItems = map(array, ({ link, icon, name }) => {
    const depositBonus =
      hasActiveDepositBonus && (name === 'deposit_withdraw' || name === 'deposit');
    const fullLink = parentPath ? `${parentPath}/${link}` : link;

    return (
      <LinkListItem
        key={fullLink}
        link={fullLink}
        refProps={pathname === fullLink ? activeItemRef : null}
        icon={icon}
        name={t(name)}
        onClick={onClickHandler}
        activeItem={activeItem}
        className={`link-list ${itemClassName || ''} ${depositBonus ? 'link-list--has-bonus' : ''}`}
      />
    );
  });

  if (isScroll) {
    return <Scrollbars className='scroll__container'>{renderItems}</Scrollbars>;
  }

  return renderItems;
};

export default LinkList;
