import React from 'react';
import { Link } from 'react-router-dom';
import { Bus, DateUtils, useApplicationState } from '@apollo/core';
import cx from 'classnames';
import { RaceTypeAll } from '@apollo/core/src/constants';
import { RacingIcon } from './RacingIcon';
import { generateRaceLink } from '../../../core/utils';

const RacingTreeItem = ({
  id,
  name,
  raceDate,
  raceType,
  raceCountry,
  raceVenue,
  raceNumber,
  active,
  showFlag = false,
  showIcon = false,
}) => {
  const { layout } = useApplicationState();

  let routeParams = {
    raceDate: raceDate || DateUtils.todayDateKey(),
    raceType,
  };

  if (id !== RaceTypeAll.id) {
    routeParams = {
      ...routeParams,
      raceCountry,
      raceVenue,
      raceNumber,
    };
  }
  const toUrl = generateRaceLink(routeParams);

  const onClickHandler = React.useCallback(() => {
    if (layout.mobileDevice === true) {
      Bus.send({
        event: Bus.events.layout.setSideBarLeft,
        data: false,
      });
    }
    // historyPush(toUrl);
    return true;
  }, [toUrl, layout.mobileDevice]);

  const className = cx(
    'sidebar-tree__section-item sidebar-tree__section-item--race',
    `race-${id}`,
    {
      active,
    },
  );

  return (
    <Link to={toUrl} className={className} onClick={onClickHandler}>
      {showIcon && raceType && <RacingIcon id={id} name={name} />}
      {showFlag && raceCountry && <i className={`icon-flag flag--${raceCountry}`} title={name} />}

      <span className='name'>{name}</span>
    </Link>
  );
};
export default RacingTreeItem;
