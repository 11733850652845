import { RaceTypesMap } from '@apollo/core/src/constants';
import React from 'react';
import RaceLink from '../../../../shared/components/Racing/RaceLink';
import { RacingIcon } from '../../../../shared/components/Racing/RacingIcon';

const RaceTypeButton = ({ raceType, raceDate }) => (
  <div className={`type__container type__container--${raceType.toLowerCase()}`}>
    <RaceLink
      className='race-link-all '
      raceDate={raceDate}
      raceType={RaceTypesMap?.[raceType].typeName}
    >
      <div className='type__container-inner'>
        <div className='type__item-icon-blank' />
        <div className='type__item-group-icon'>
          <RacingIcon id={RaceTypesMap?.[raceType]?.id} name={RaceTypesMap?.[raceType]?.typeName} />
        </div>
      </div>
      <div className='type__title'>
        <div>
          <span>{RaceTypesMap?.[raceType].typeName}</span>
        </div>
      </div>
    </RaceLink>
  </div>
);
export default RaceTypeButton;
