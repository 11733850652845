import React from 'react';
import {
  BET_STATUSES,
  RaceLogicMarkets,
  RaceMarketsMultilegsTabs,
  RaceMarketsTabs,
} from '@apollo/core/src/constants';
import { betTypes } from '../../../core/constants';

const getBetStatusByCode = (code) => {
  switch (code) {
    case 'W':
      return BET_STATUSES.WIN;
    case 'L':
      return BET_STATUSES.LOSE;
    case 'V':
      return BET_STATUSES.VOID;
    case 'O':
      return 'LEG_IS_OPEN';
    case 'A':
      return 'LEG_IS_ABANDONED';
    case 'E':
      return 'INTERNAL_ERROR';
    default:
      return '';
  }
};

const getOutcomeStatus = (outcomeInfo, outcomePosition, betType) => {
  if (betType === betTypes.MULTIPLE) {
    return outcomeInfo.outcomeStatus;
  }

  if (betType === 'SGM') {
    return outcomeInfo;
  }

  // Racing
  switch (outcomeInfo?.marketTypeId) {
    case RaceMarketsTabs.SAME_RACE_MULTI:
    case RaceMarketsMultilegsTabs.QUADDIE:
    case RaceMarketsMultilegsTabs.E_QUADDIE:
    case RaceMarketsMultilegsTabs.TREBLE:
    case RaceMarketsMultilegsTabs.BIG6:
    case RaceMarketsMultilegsTabs.R_DOUBLE:
    case RaceMarketsMultilegsTabs.D_DOUBLE:
    case RaceLogicMarkets.EXACTA:
    case RaceLogicMarkets.QUIN:
    case RaceLogicMarkets.TRIFECTA:
    case RaceLogicMarkets.FIRST4:
      if (outcomeInfo?.segmentsStatus) {
        return getBetStatusByCode(outcomeInfo.segmentsStatus.split('/')[outcomePosition]);
      }
      if (outcomeInfo?.outcomeTypeId) {
        return getBetStatusByCode(outcomeInfo.outcomeTypeId.split('/')[outcomePosition]);
      }
      return null;
    case RaceLogicMarkets.COMBINED:
      /*  if (outcomeInfo?.raceResults) {
        const betPlaces = outcomeInfo.parameterValue?.split(',');
        const resultPlaces = outcomeInfo.raceResults.split(',');
        if (betPlaces[outcomePosition] === resultPlaces[outcomePosition]) {
          return BET_STATUSES.WIN;
        }
        return BET_STATUSES.LOSE;
      } */
      return null;
    default:
      return null;
  }
};

const MultiStatusIcon = ({ outcomeInfo, outcomePosition = 0, betType = betTypes.SINGLE }) => {
  const outcomeStatus = getOutcomeStatus(outcomeInfo, outcomePosition, betType);

  switch (outcomeStatus) {
    case BET_STATUSES.WIN:
      return <span className='AkIcon-check-icon icon-status icon-status--win' />;
    case BET_STATUSES.LOSE:
      return <span className='AkIcon-close-icon icon-status icon-status--lose' />;
    case BET_STATUSES.VOID:
    case 'LEG_IS_ABANDONED':
      return <span className='AkIcon-check-icon icon-status' />;
    case 'INTERNAL_ERROR':
      return <span className='icon-status icon-status--error' />;
    case 'LEG_IS_OPEN':
      return <span className='AIcon-time-icon icon-status' />;
    default:
      return null;
  }
};

export default MultiStatusIcon;
