import { SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import React, { useEffect, useMemo } from 'react';
import { isEmpty, map } from 'lodash';
import { Bus, isHidden } from '@apollo/core';
import { SPORT_SERVICE } from '@apollo/core/src/constants';
import { hasMarketTypes } from '@apollo/core/src/utils/status';
import SportEvents from '../../../../features/Home/Tabs/Sports/SportEvents';
import useTranslate from '../../I18n/Interpreter';

const TopTournaments = ({ title = null }) => {
  const [sportState] = useSportState();
  const { eventsByTournamentId } = sportState;

  const t = useTranslate();
  const finalTitle = useMemo(() => (!isEmpty(title) ? title : ''), [title]);

  const topTournaments = SportSelector.selectTopTournaments({
    // limit: 5,
    excludeOutright: true,
  });

  // Ensure we have some top tournament events in the state
  useEffect(() => {
    const tournamentIds = topTournaments.map((t) => t.id);
    if (isEmpty(tournamentIds)) {
      return;
    }

    tournamentIds.forEach((id) => {
      Bus.emit(Bus.events.sport.sportLoadEvents, {
        filter: {
          isOnlyMainMarketTypes: true,
          fromDate: Date.now(),
          toDate: null,
          tournamentIds: [id],
        },
        paging: {
          itemsPerPage: 10,
          currentPage: 0,
        },
      });
    });
  }, [topTournaments]);

  if (isEmpty(topTournaments) === true) {
    return null;
  }

  return (
    <div className='home-sports-list home-sports-list--tournaments'>
      {/* !isEmpty(finalTitle) && <h3 className='top-tournaments__title'>{finalTitle}</h3> */}
      {map(topTournaments, (tournament) => (
        <SportEvents
          key={tournament.id}
          title={tournament.name}
          events={(eventsByTournamentId[tournament.id] || []).filter(
            (e) => !isHidden(e) && hasMarketTypes(e) && e.sportService === SPORT_SERVICE.PREMATCH
          )}
        />
      ))}
    </div>
  );
};
export default TopTournaments;
