import React from 'react';
import { useApplicationState } from '@apollo/core';
import { isEmpty, map } from 'lodash';
import Label from '../../../../shared/components/I18n/Label';
import RaceFlucsHeader from '../../raceFlucs/RaceFlucsHeader';
import { RacingMarketTypeName } from '../../../../shared/components/Racing/RacingMarketTypeName';

const sortableMarkets = ['F_WIN', 'F_PLC', 'FAV_OUT', 'CONCESSION'];

const sortByRunnerField = 'runner';

const RacePageTableHeader = ({
  availableRaceMarkets,
  activeColumnsMobile,
  availableRaceMarketsMobile,
  sortByField,
  setSortByField,
  activeRace,
  isFlucs,
}) => {
  const { layout } = useApplicationState();

  const onSort = (field) => {
    if (field === sortByRunnerField || sortableMarkets.includes(field)) {
      setSortByField({
        field,
        asc: sortByField.field === field ? !sortByField.asc : true,
      });
    }
  };

  return (
    <div className='tournament__header'>
      <div className='event event--classic'>
        <div className='event__main-period'>
          <div className='event__info'>
            <div className='event-participants'>
              <div
                className={`event-participants__participant sorting ${
                  sortByField.field === sortByRunnerField && (sortByField.asc ? 'asc' : 'desc')
                }`}
                onClick={() => onSort(sortByRunnerField)}
              >
                <Label message='Runner' />
              </div>
            </div>
          </div>
          {!_.isEmpty(activeRace?.fwinOutcomesWithOddsHistories) ? <RaceFlucsHeader /> : null}
          {layout.mobileDevice === false ? (
            <div className='event__market-row desktop__values'>
              {isEmpty(availableRaceMarkets) === false
                && map(availableRaceMarkets, (mt) => (
                  <div
                    key={`${mt?.marketType}`}
                    className='event__market-row-market'
                    onClick={() => onSort(mt?.marketType)}
                  >
                    <span
                      className={`${sortableMarkets.includes(mt?.marketType) ? 'sorting' : ''} ${
                        sortByField.field === mt?.marketType && (sortByField.asc ? 'asc' : 'desc')
                      }`}
                    >
                      <RacingMarketTypeName marketType={mt?.marketType} />
                    </span>
                  </div>
                ))}
            </div>
          ) : null}
          {layout.mobileDevice === true ? (
            <div className='event__market-row mobile__values'>
              {isEmpty(availableRaceMarkets) === false
                && map(availableRaceMarketsMobile[activeColumnsMobile], (mt) => (
                  <div key={`${mt?.marketType}`} className='event__market-row-market'>
                    <RacingMarketTypeName marketType={mt?.marketType} />
                  </div>
                ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default RacePageTableHeader;
