import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { isFunction } from 'lodash';

const MODAL_ROOT_NODE_ID = 'modal-root';

const ModalPortal = ({ children, className = '', forceInteraction = false, onClose = null, scrollable = false }) => {
  const { push: historyPush } = useHistory();
  const { pathname } = useLocation();

  const rootRef = React.useRef(null);
  if (!rootRef.current) {
    rootRef.current = document.getElementById(MODAL_ROOT_NODE_ID);
  }

  const close = () => {
    if (isFunction(onClose)) {
      onClose();
    }
    historyPush(pathname);
  };

  const closePopup = (e) => {
    if (!e.target.className?.startsWith('modal__container')) {
      return;
    }

    if (forceInteraction) {
      return;
    }

    document.addEventListener(
      'mouseup',
      () => close(),
      { once: true },
    );
  };

  useEffect(() => {
    const listener = (event) => {
      if (['Escape'].includes(event.key) && !forceInteraction) {
        close();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={`modal ${className || ''}`}>
      <div className='modal__backdrop' />
      <div className={`modal__container ${scrollable ? 'modal__container--scrollable' : ''}`} onMouseDown={closePopup}>
        <div className='modal__content'>
          {children}
        </div>
      </div>
    </div>,
    rootRef.current,
  );
};
export default ModalPortal;
