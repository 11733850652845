import React, { useMemo } from 'react';
import { cmsWidgetComponent } from '../../../../core/constants';
import WidgetWrapper from './Common/WidgetWrapper';
import RacingFeaturedEvent from './RacingFeaturedEvent';
import SportFeaturedEvent from './SportFeaturedEvent';
import Upcoming from './Upcoming';
import TopSports from './TopSports';
import TopTournaments from './TopTournaments';
import { safeJSONparse } from '../../../../core/utils';

const WidgetLoader = ({ widget }) => {
  const { type, widget_data: data } = widget;
  const widgetData = safeJSONparse(data);

  const widgetComponent = useMemo(() => {
    switch (type) {
      case cmsWidgetComponent.RacingFeaturedEvent:
        return <RacingFeaturedEvent data={widgetData} />;

      case cmsWidgetComponent.SportFeaturedEvent:
        return <SportFeaturedEvent data={widgetData} />;

      case cmsWidgetComponent.SportUpcoming:
        return <Upcoming {...widgetData} />;

      case cmsWidgetComponent.SportTopSports:
        return <TopSports {...widgetData} />;

      case cmsWidgetComponent.SportTopTournaments:
        return <TopTournaments {...widgetData} />;

      default:
        return null;
    }
  }, [widget, data, type]);

  return <WidgetWrapper type={type}>{widgetComponent}</WidgetWrapper>;
};
export default WidgetLoader;
