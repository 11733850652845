import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { RaceTypesMap } from '@apollo/core/src/constants';
import { map } from 'lodash';
import FilterRaceType from './FilterRaceType';
import useNextRaces from '../../../../features/Racing/useNextRaces';
import Label from '../../I18n/Label';
import Dropdown from '../../Dropdown/Dropdown';
import RaceLink from '../../Racing/RaceLink';
import { RacingIcon } from '../../Racing/RacingIcon';
import RaceCountdown from '../../Racing/RaceCountdown/RaceCountdown';
import { AnimatedDropdown } from '../../../../features/Animations/AnimatedDropdown';

const NextToJumpBar = () => {
  const [selectedRaceTypes, setSelectedRaceTypes] = useState(Object.keys(RaceTypesMap));
  const [isExpanded, setExpanded] = useState(false);
  const toggleRef = useRef(null);
  const scrollbarsRef = useRef(null);
  const nextRaceListRef = useRef(null);
  const intervalRef = useRef(null);

  const [nextToJump, nextRacesUpdate] = useNextRaces({
    raceTypes: selectedRaceTypes,
  });

  const getVisibleScrollWidth = () => nextRaceListRef.current.offsetWidth;

  const shrinkDropdown = useCallback(() => {
    setExpanded(false);
  }, [setExpanded]);

  const onSelectRaceType = React.useCallback(
    (e) => {
      let updated = [...selectedRaceTypes];
      if (updated?.includes(e)) {
        updated = selectedRaceTypes.filter((item) => item !== e);
      } else {
        updated.push(e);
      }
      if (updated.length) {
        setSelectedRaceTypes(updated);
      }
    },
    [selectedRaceTypes]
  );

  const filtersList = useMemo(
    () =>
      _.map(Object.keys(RaceTypesMap), (raceType) => (
        <div key={RaceTypesMap?.[raceType]?.id}>
          <FilterRaceType
            typeName={RaceTypesMap?.[raceType]?.typeName}
            id={RaceTypesMap?.[raceType]?.id}
            raceType={raceType}
            isChecked={selectedRaceTypes.includes(raceType)}
            onClick={() => onSelectRaceType(raceType)}
          />
        </div>
      )),
    [selectedRaceTypes]
  );

  const handleScroll = (delta) => {
    if (scrollbarsRef.current && !intervalRef.current) {
      const visibleScrollWidth = getVisibleScrollWidth();
      const startScrollLeft = scrollbarsRef.current.getScrollLeft();
      let targetScrollLeft = startScrollLeft + delta;

      if (
        (delta < 0 && startScrollLeft === 0) ||
        (delta > 0 &&
          startScrollLeft === scrollbarsRef.current.getScrollWidth() - visibleScrollWidth)
      ) {
        return;
      }

      targetScrollLeft = Math.max(
        0,
        Math.min(targetScrollLeft, scrollbarsRef.current.getScrollWidth() - visibleScrollWidth)
      );

      const step = delta < 0 ? -10 : 10;
      const duration = 10;

      intervalRef.current = setInterval(() => {
        const currentScrollLeft = scrollbarsRef.current.getScrollLeft();
        const newScrollLeft = currentScrollLeft + step;

        scrollbarsRef.current.scrollLeft(newScrollLeft);

        if (
          (delta > 0 && newScrollLeft >= targetScrollLeft) ||
          (delta < 0 && newScrollLeft <= targetScrollLeft)
        ) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }

        if (
          (delta < 0 && newScrollLeft <= 0) ||
          (delta > 0 &&
            newScrollLeft >= scrollbarsRef.current.getScrollWidth() - visibleScrollWidth)
        ) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }, duration);
    }
  };

  const handleScrollRight = () => {
    handleScroll(getVisibleScrollWidth());
  };

  const handleScrollLeft = () => {
    handleScroll(-getVisibleScrollWidth());
  };

  return (
    <div className={classNames('next-jump-bar')}>
      <div className='next-jump-bar__item next-jump-bar__item--title'>
        <Label message='next_to_jump' className='next-jump-bar__title' />
      </div>
      <div className='next-jump-bar__item next-jump-bar__item--filters' style={{ minWidth: 80 }}>
        <Dropdown
          onExpanded={setExpanded}
          className='filter-race-type-container'
          toggleRef={toggleRef}
        >
          <div ref={toggleRef}>
            <Label message='Filter' />
          </div>
          <AnimatedDropdown isExpanded={isExpanded} className='history__dropdown'>
            {filtersList}
          </AnimatedDropdown>
        </Dropdown>
      </div>
      <div className='next-race-list' ref={nextRaceListRef}>
        <Scrollbars className='next-race-list__scroll' ref={scrollbarsRef}>
          {map(nextToJump, (r) => (
            <RaceLink
              key={r.raceId}
              className='next-jump-bar__item'
              raceDate={r.meeting?.date}
              raceType={r.raceType?.typeName}
              raceCountry={r.venue?.country}
              raceVenue={r.venue?.venueName}
              raceNumber={r.number}
            >
              <RacingIcon id={r.raceType?.id} name={r.raceType?.typeName} />
              <span className='race-name'>{r.venue?.venueName}</span>
              <span className='race-number'>R{r.number}</span>

              <RaceCountdown
                key={`${r?.raceId}-${r?.status}-${r?.result}`}
                date={new Date(r.startTime)}
                completeText={r.result || r.status}
                onComplete={nextRacesUpdate}
              />
            </RaceLink>
          ))}
        </Scrollbars>
      </div>
      <div className='next-race-nav'>
        <button type='button' className='next-race-nav__button' onMouseDown={handleScrollRight}>
          <span className='icon AIcon-angle-right' />
        </button>
        <button type='button' className='next-race-nav__button' onClick={handleScrollLeft}>
          <span className='icon AIcon-angle-left' />
        </button>
      </div>
    </div>
  );
};

export default NextToJumpBar;
