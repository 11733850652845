import React, { useCallback, useEffect, useState } from 'react';
import { get, isArray, map } from 'lodash';
import cx from 'classnames';
import { useApplicationState, usePreferences } from '@apollo/core';
import Outcome from '../../../../shared/components/DetailOdds/Outcome';
import Label from '../../../../shared/components/I18n/Label';
import IconFavorite from '../../../../shared/components/IconFavorite/IconFavorite';
import SgmOutcome from '../Sgm/SgmOutcome';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import { sportProviders } from '../../../../core/constants';
import { getMarketTypeDescriptions } from '../../../../core/DataParser/marketTypeDescription';
import { AnimatedDropdown } from '../../../Animations/AnimatedDropdown';

const MarketTypeDefaultView = ({
  event,
  marketType,
  isFavorite,
  isArchived,
  data,
  marketTypesShowState,
  setMarketTypesShowState,
  isBadgeHeaderHidden,
  isSgm,
  activeGroup,
}) => {
  const t = useTranslate();

  // marketType from processedMarketTypes
  const [isExpanded, setIsExpanded] = useState(
    get(
      marketTypesShowState,
      `${event.sportId}.${marketType.id}`,
      activeGroup?.group ? marketType?.show : marketType?.groups?.includes('MAIN')
    )
  );

  useEffect(() => {
    setIsExpanded(
      get(
        marketTypesShowState,
        `${event.sportId}.${marketType.id}`,
        activeGroup?.group ? marketType?.show : marketType?.groups?.includes('MAIN')
      )
    );
  }, [activeGroup]);

  const { selectFavoriteMarketType } = isArchived ? {} : usePreferences();
  const applicationState = useApplicationState();
  const authenticated = get(applicationState, 'authenticated');

  const selectFavoriteMarketTypeHandler = useCallback(
    (event) => {
      event.stopPropagation();

      selectFavoriteMarketType({ marketTypeId: marketType.id });
    },
    [marketType.id, selectFavoriteMarketType]
  );

  const toggleExpand = useCallback(() => {
    if (!isArchived) {
      setMarketTypesShowState((marketTypesShowState) => ({
        ...marketTypesShowState,
        [event?.sportId]: {
          ...marketTypesShowState[event?.sportId],
          [marketType.id]: !isExpanded,
        },
      }));
    }
    setIsExpanded((isExpanded) => !isExpanded);
  }, [setIsExpanded, isExpanded]);

  // Show Icon on Singles ONLY if the market is from Sportscast (T&Cs)
  const showSgmIcon = React.useMemo(
    () => Boolean(marketType.providerName === sportProviders.Sportcast || isSgm),
    [isSgm, marketType.providerName]
  );

  const marketTitleClassName = cx('marketGroup__title', { isExpanded });

  // const favIconClassName = cx('icon_fav AkIcon-favorite-icon', {
  //   active: isFavorite,
  // });

  const classes = cx('MarketTypeDefaultView__cell', 'marketGroup__content');

  const marketTypeDescription = getMarketTypeDescriptions(marketType.id);

  return (
    <div
      className={`eventView__market ${marketType.displayLayout ? `eventView__market--${marketType.displayLayout.toLowerCase()}` : ''}`}
    >
      <div className={cx('marketGroup')}>
        <div
          className={marketTitleClassName}
          onClick={toggleExpand}
          title={`id:${marketType.id}:${marketType.providerName?.slice(0, 2)}`}
        >
          {/* <span className='sort-order'>{marketType.orderPosition || 'NULL'}</span> */}
          <span className='name'>{marketType.name}</span>
          {!isBadgeHeaderHidden && (
            <Label
              message='bet group title'
              render={(translation) => (
                <span className='relatedMarket' title={translation}>
                  <span className='relatedMarket__icon AkIcon-link-icon' />
                  <If condition={marketType.betGroups}>
                    <span className='relatedMarket__content'>
                      {
                        // temporary decision before core lib will be fixed
                        isArray(marketType.betGroups)
                          ? marketType.betGroups.join(', ')
                          : Object.values(marketType.betGroups).join(', ')
                      }
                    </span>
                  </If>
                </span>
              )}
            />
          )}
          {authenticated && !isBadgeHeaderHidden && (
            <IconFavorite isActive={isFavorite} onClick={selectFavoriteMarketTypeHandler} />
          )}
          {showSgmIcon && <span className='icons icon--sgm-green' title={t('sgm')} />}
          <span className='icon AIcon-angle-right' />
        </div>
        <AnimatedDropdown isExpanded={isExpanded} className='marketGroup__rows expanded'>
          <If condition={marketTypeDescription}>
            <div className='MarketTypeDescription'>{marketTypeDescription}</div>
          </If>
          <div className='MarketTypeDefaultView'>
            <If condition={data?.header?.length}>
              <div className='MarketTypeDefaultView__competitors-block '>
                {map(data.header, (v, index) => {
                  if (!v) {
                    return <div key={index} className='MarketTypeDefaultView__cell' />;
                  }
                  return (
                    <div key={index} className='MarketTypeDefaultView__cell'>
                      {v}
                    </div>
                  );
                })}
              </div>
            </If>
            {map(data.rows, (row, rowIndex) => (
              <div key={`${marketType.id}-${rowIndex}`} className='MarketTypeDefaultView__row'>
                {map(row, (outcome, indexInRow) => {
                  if (!outcome) {
                    return null;
                  }
                  const outcomeClasses = cx([
                    'market',
                    'bet',
                    isArchived &&
                      outcome.settlementStatus &&
                      outcome.settlementStatus.toLowerCase(),
                    (!outcome?.name || outcome.isFake) && 'center',
                    classes,
                    outcome.isFake && outcome.className,
                  ]);

                  if (isArchived && outcome.settlementStatus) {
                    outcome.odds = outcome.settlementStatus.replace('_', ' ');
                  }

                  return isSgm ? (
                    <SgmOutcome
                      key={`${marketType.id}-${outcome.id}`}
                      event={event}
                      className={outcomeClasses}
                      marketType={marketType}
                      outcome={outcome}
                      indexInRow={indexInRow}
                    />
                  ) : (
                    <Outcome
                      key={`${marketType.id}-${outcome.marketId}-${outcome.id}`}
                      event={event}
                      className={outcomeClasses}
                      marketType={marketType}
                      outcome={outcome}
                      indexInRow={indexInRow}
                    />
                  );
                })}
              </div>
            ))}
          </div>
        </AnimatedDropdown>
      </div>
    </div>
  );
};

export default MarketTypeDefaultView;
