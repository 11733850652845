import React from 'react';
import { centsToDecimal } from '@apollo/core';
import ProgressBar from './ProgressBar';

const BonusTurnoverTracker = ({ eligibilityStats }) => {
  const { eligibleTurnover = 0, requiredTurnover = 0 } = eligibilityStats;
  const required = requiredTurnover;
  const current = Math.min(eligibleTurnover, required);

  if (!required) {
    return null;
  }

  return (
    <div className='bonus-eligibility-limit bonus-eligibility-limit--turnover'>
      <ProgressBar
        current={centsToDecimal(current)}
        required={centsToDecimal(required)}
        tooltip={`${centsToDecimal(current)} out of ${centsToDecimal(required)}`}
      />
    </div>
  );
};

export default React.memo(BonusTurnoverTracker);
