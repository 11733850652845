import React from 'react';
import {
  clientProfileRoutes,
  accountRoutes,
  CLIENT_PROFILE_COMPONENT_TYPES,
} from '@apollo/routing';
import DetailMain from './DetailMain';
import ChangePassword from './ChangePassword';
import VerifyDocuments from './VerifyDocuments';
import ClientProfileSubRouter from '../ClientProfileSubRouter';

const { profile } = clientProfileRoutes;

const getAvailableRoutes = (clientProfilePath) => ({
  [CLIENT_PROFILE_COMPONENT_TYPES.PROFILE_MAIN]: {
    path: `${clientProfilePath}/${profile}/${accountRoutes.main}`,
    component: DetailMain,
  },
  [CLIENT_PROFILE_COMPONENT_TYPES.PROFILE_CHANGE_PASSWORD]: {
    path: `${clientProfilePath}/${profile}/${accountRoutes.changePassword}`,
    component: ChangePassword,
  },
  [CLIENT_PROFILE_COMPONENT_TYPES.PROFILE_VERIFY]: {
    path: `${clientProfilePath}/${profile}/${accountRoutes.verifyDocuments}`,
    component: VerifyDocuments,
  },
});

const DetailRouter = ({ tabs }) => (
  <ClientProfileSubRouter
    tabs={tabs}
    getAvailableRoutes={getAvailableRoutes}
    parentRoute={profile}
  />
);

export default DetailRouter;
