import React, { useEffect } from 'react';
import { BetHistoryProvider, createBetHistoryState, useApplicationState } from '@apollo/core';
import { BET_STATUS_ALL } from '@apollo/core/src/ui/betHistory/constants';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import BetHistoryFilterListContainer from './BetHitoryFilterListContainer';
import Svg from '../../../../shared/components/svg/Svg';
import BetHistoryTableList from './TableList/BetHistoryTableList';
import Pagination from '../../../../shared/components/Pagination';
import BetHistoryMobile from './BetHistoryMobile';
import { prepareBetSlipRunners } from '../../../../core/utils';

const BetHistory = () => {
  const betHistoryState = createBetHistoryState({ betStatus: BET_STATUS_ALL });
  const { layout } = useApplicationState();
  const t = useTranslate();
  const { totalPages, currentPage, setCurrentPage, bets, isLoading } = betHistoryState;

  useEffect(() => {
    prepareBetSlipRunners(bets);
  }, [bets, currentPage]);

  return (
    <BetHistoryProvider value={betHistoryState}>
      {layout.mobileDevice ? (
        <BetHistoryMobile />
      ) : (
        <>
          <BetHistoryFilterListContainer />
          {!isLoading && totalPages === 0 && bets.length === 0 ? (
            <div className='empty-state-history'>
              <div className='svg-container'>
                <Svg name='emptyState' />
              </div>
              <span>{t('There is no items yet')}</span>
            </div>
          ) : (
            <>
              <div className='history-table'>
                <div className='history-table-header bet-history'>
                  <div className='history-table-row'>
                    <div className='bet-history-row'>
                      <div className='history-table-cell date-id'>
                        <span className='date'>
                          {t('date')}/{t('id')}
                        </span>
                      </div>
                      <div className='history-table-cell'>
                        <span className='time'>{t('event')}</span>
                      </div>
                      <div className='history-table-cell'>
                        <span className='betId'>{t('selection')}</span>
                      </div>
                      {/* <div className='history-table-cell'>
                      <span className='betType'>{t('final score')}</span>
                    </div> */}
                      <div className='history-table-cell'>
                        <span className='amount'>{t('bet')}</span>
                      </div>
                      <div className='history-table-cell'>
                        <span className='result'>{t('odds')}</span>
                      </div>
                      <div className='history-table-cell'>
                        <span className='result'>{t('payout')}</span>
                      </div>
                      <div className='history-table-cell'>
                        <span className='result'>{t('status')}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='history-table-body bet-history'>
                  <BetHistoryTableList />
                </div>
              </div>
              {totalPages > 1 && (
                <Pagination
                  pages={totalPages}
                  currentPage={currentPage}
                  changeCurrentPage={setCurrentPage}
                />
              )}
            </>
          )}
        </>
      )}
    </BetHistoryProvider>
  );
};

export default BetHistory;
