import React from 'react';
import cx from 'classnames';
import RaceLink from '../../../shared/components/Racing/RaceLink';

const RaceFilterListItem = ({ id, name, raceDate, raceType, active, className }) => {
  const classNames = cx(className, {
    active,
  });

  return (
    <div key={id} className={classNames}>
      <RaceLink key={id} raceDate={raceDate} raceType={raceType} active={active}>
        {name}
      </RaceLink>
    </div>
  );
};
export default RaceFilterListItem;
