import { find, map } from 'lodash';
import React from 'react';
import RaceCountdownMobile from '../../../shared/components/Racing/RaceCountdown/RaceCountdownMobile';
import { RACE_COUNTRIES, RACE_STATUS } from '../../../core/constants';
import Label from '../../../shared/components/I18n/Label';
import RaceLink from '../../../shared/components/Racing/RaceLink';
import { getRaceCompleteText } from '../../../core/utils';
import { RacingIcon } from '../../../shared/components/Racing/RacingIcon';
import BetReturnBonusIcon from '../../../shared/components/Bonus/BetReturnBonusIcon';
import PromoOddsBonusIcon from '../../../shared/components/Bonus/PromoOddsBonusIcon';

const VenuePageMobile = ({ raceType: rt, raceDate, selectedCountries }) => {
  const renderNextOpenRace = (race) => {
    if (!race) {
      return <Label className='race-summary__timer__wrapper' message='Resulted' />;
    }

    return (
      <div className='race-summary__timer__wrapper'>
        <div className='race-summary__timer'>
          <RaceCountdownMobile
            race={race}
            key={`${race.raceId}-${race.status}-${race.result}`}
            date={new Date(race.startTime)}
            completeText={getRaceCompleteText(race)}
          />
        </div>
        {race?.hasFixedOdds ? <div className='race-summary__fixed-odds'>FO</div> : null}
        <BetReturnBonusIcon race={race} />
        <PromoOddsBonusIcon race={race} />
      </div>
    );
  };

  return (
    <div className='venue_race_table venue_race__mobile'>
      <div key={rt.typeName} className='race_type_header__container'>
        <div className='race_type_header'>
          <RacingIcon id={rt.id} name={rt.typeName} />
          <div className='tournament-name' title={rt.typeName}>
            {rt.typeName}
          </div>
        </div>
      </div>
      <div className='venue_race_table_data '>
        <div>
          {rt?.venues.local.length && selectedCountries[RACE_COUNTRIES.local] ? (
            <>
              {map(rt?.venues.local, (venue) => {
                const race = find(venue?.races, (race) => race.status === RACE_STATUS.OPEN);
                return (
                  <RaceLink
                    className='venue_race_table_row'
                    raceDate={raceDate}
                    raceType={venue.raceType.typeName}
                    raceCountry={venue.country}
                    raceVenue={venue.venueName}
                    // raceNumber={venue?.races[0]?.number}
                    raceNumber={race?.number}
                    key={venue.venueId}
                  >
                    <div className='venue_race_table_row_item__data first'>
                      <div className='venue_race_table_row_item__data__link'>
                        <span className='name'>{venue.venueName}</span>
                        <div className='country'>
                          {venue.countryName}
                          ,&nbsp;
                          {venue.state}
                        </div>
                      </div>
                    </div>
                    <div className={`venue_race_table_row_item__data ${race?.status}`}>
                      {renderNextOpenRace(race)}
                    </div>
                  </RaceLink>
                );
              })}
            </>
          ) : null}
          {rt.venues.international.length && selectedCountries[RACE_COUNTRIES.international] ? (
            <>
              {selectedCountries[RACE_COUNTRIES.local] ? (
                <div className='venue_race_table_row_international'>
                  <div>
                    <Label message='International' />
                  </div>
                </div>
              ) : null}
              {map(rt.venues.international, (venue) => {
                const race = find(venue?.races, (race) => race.status === RACE_STATUS.OPEN);
                return (
                  <RaceLink
                    className='venue_race_table_row'
                    raceDate={raceDate}
                    raceType={venue.raceType.typeName}
                    raceCountry={venue.country}
                    raceVenue={venue.venueName}
                    raceNumber={race?.number}
                    key={venue.venueId}
                  >
                    <div className='venue_race_table_row_item__data first'>
                      <div className='venue_race_table_row_item__data'>
                        <div className='venue_race_table_row_item__data__link'>
                          <span className='name'>{venue.venueName}</span>
                          <div className='country'>{venue.countryName}</div>
                        </div>
                      </div>
                    </div>
                    <div className={`venue_race_table_row_item__data ${race?.status}`}>
                      {renderNextOpenRace(race)}
                    </div>
                  </RaceLink>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default VenuePageMobile;
