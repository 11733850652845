import React, { useEffect, useMemo } from 'react';
import { createBetHistoryState } from '@apollo/core';
import { isEmpty, map } from 'lodash';
import { BET_STATUSES } from '@apollo/core/src/constants';
import MyBetsFilter from './MyBetsFilter';
import MyBetsItem from './MyBetsItem';
import MyBetsEmptyIcon from './MyBetsEmptyIcon';
import Label from '../../../shared/components/I18n/Label';
import { prepareBetSlipRunners } from '../../../core/utils';
import Loader from '../../../shared/components/Loader';

const MyBets = () => {
  const { isLoading, bets, replaceBet, onSelectBetStatus, selectedBetStatus } =
    createBetHistoryState({
      betStatus: BET_STATUSES.UNSETTLED,
      itemsPerPage: 100,
    });

  useEffect(() => {
    prepareBetSlipRunners(bets);
  }, [bets]);

  const content = useMemo(() => {
    if (isLoading || !bets) {
      return (
        <div className='bets__myBets'>
          <div className='bets__myBets__empty'>
            <Loader />
          </div>
        </div>
      );
    }

    if (isEmpty(bets)) {
      return (
        <div className='bets__myBets'>
          <div className='bets__myBets__empty'>
            <MyBetsEmptyIcon />
            <Label
              message='you_have_no_bets'
              render={(t) => <div className='emptyText'>{t}</div>}
            />
          </div>
        </div>
      );
    }

    return map(bets, (bet) => <MyBetsItem key={bet.betId} bet={bet} replaceBet={replaceBet} />);
  }, [isLoading, bets]);

  return (
    <div className='tab__content active'>
      <MyBetsFilter onSelectBetStatus={onSelectBetStatus} selectedBetStatus={selectedBetStatus} />
      <div className='bets__myBets'>{content}</div>
    </div>
  );
};

export default MyBets;
