import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Label from '../../../../shared/components/I18n/Label';
import { getLimitWarning } from '../../../../shared/components/Form/formConfig/responsibleGaming';

const RenderMainStateRow = ({ value, type, future, currencyIcon }) => {
  const futureMarkup = useMemo(() => {
    const warning = getLimitWarning(future);

    if (!warning) return null;

    return (
      <span className='warning-message'>
        <Label {...warning} />
      </span>
    );
  }, [currencyIcon, future]);

  return (
    <div className='limits-readonly-row'>
      <div>
        <Label className='limits-readonly-row__label' message={type} />
        <span className='limits-readonly-row__value'>{value}</span>
      </div>
      {futureMarkup}
    </div>
  );
};

RenderMainStateRow.defaultProps = {
  currencyIcon: '',
  notification: '',
  future: null,
};

RenderMainStateRow.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  notification: PropTypes.string,
  future: PropTypes.shape({
    dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  currencyIcon: PropTypes.string,
};

export default RenderMainStateRow;
