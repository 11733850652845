import _ from 'lodash';
import { constants } from '@apollo/core';

const { DISPLAY_LAYOUTS } = constants;

export const sortBySpec = (arrOfArrays) => {
  const countOfSpecParams = _.split(arrOfArrays[0][0].specifiers, '/').length;
  for (let i = 0; i < countOfSpecParams; i += 1) {
    arrOfArrays.sort((firstArr, secondArr) => {
      const firstItemParams = _.split(firstArr[0].specifiers, '/');
      const secondItemParams = _.split(secondArr[0].specifiers, '/');
      const firstItemCompareParam = parseInt(
        firstItemParams[countOfSpecParams - 1 - i],
      );
      const secondItemCompareParam = parseInt(
        secondItemParams[countOfSpecParams - 1 - i],
      );
      return firstItemCompareParam - secondItemCompareParam;
    });
  }
  return arrOfArrays;
};

export const outcomeNameParser = ({
  outcome: { specifiers, name }, sportId, marketType, marketType: { displayLayout },
}) => {
  let paramsArr = specifiers.split('/').map((p) => (!_.isNaN(parseFloat(p)) && parseFloat(p) % 1 === 0 ? Number(p) : p));

  paramsArr = paramsArr.map((param, index) => {
    if (paramsArr.length === 1) { // one specifiers
      if (sportId === 2) return `${param}Game`;// tennis
      // if (sportId === 17) return `${param}Set`; // volleyball
      if (sportId === 55) return `${param}Frame`; // snooker
      return `${param}Pt`;// table tennis if (sportId === 7)
    }

    if (param) param = Math.round(param);
    if (index === 0) {
      if (marketType.id === 1581 || [2, 6, 17].includes(sportId) || [17].includes(sportId) && paramsArr.length === 2) return `${param}Set`;
      return `${param}Game`;
    }
    if (index === paramsArr.length - 1) { // last param
      if ([2].includes(sportId) && paramsArr.length === 2) return `${param}Game`;
      if (displayLayout === DISPLAY_LAYOUTS.GAME_SCORE) return `to ${param}`;
      return `${param}Pt`;
    }
    return `${param}Game`;
  });

  return `${paramsArr.join(' ')}`;
};

export const handleSpecifierWithDivider = (market) => {
  const arrOfScore = market?.specifiers.split(':');
  if (!arrOfScore.length) return market?.specifiers;
  return arrOfScore.length === 2 ? arrOfScore[1] - arrOfScore[0] : market?.specifiers * -1;
};

export const sortByOrderPosition = (data) => {
  _.forEach(data, (arr, key) => {
    data[key] = _.sortBy(arr, ['orderPosition']);
  });
  return data;
};

export const oddsSoring = (a, b) => {
  const { orderPosition, odds: odds1 } = a;
  const { odds: odds2 } = b;

  const o1 = Number(odds1);
  const o2 = Number(odds2);

  if (_.isFinite(o1) && _.isFinite(o2)) {
    return o1 - o2;
  }
  return orderPosition;
};

export const diapasonSort = (aOutcomeArr, bOutcomeArr) => {
  const { param: aParam, type: aType } = aOutcomeArr[0];
  const { param: bParam, type: bType } = bOutcomeArr[0];
  const isAHasPlus = aType.includes('{specifier}+');
  const isBHasPlus = bType.includes('{specifier}+');
  const isAComparable = !isAHasPlus && (aParam.includes('-') || _.parseInt(aParam));
  const isBComparable = !isBHasPlus && (bParam.includes('-') || _.parseInt(bParam));
  const isAString = !isAComparable && !isAHasPlus;
  const isBString = !isBComparable && !isBHasPlus;

  if (isAString && isBString) {
    return 0;
  }
  if (isAString || isAHasPlus && isBComparable) {
    return 1;
  }
  if (isBString || isBHasPlus && isAComparable) {
    return -1;
  }

  const aSecondParsedParam = _.parseInt(aParam.split('-')[1] || aParam.split('-')[0]);
  const bSecondParsedParam = _.parseInt(bParam.split('-')[1] || bParam.split('-')[0]);

  return aSecondParsedParam - bSecondParsedParam;
};

export const specSortMarket = (market, asc = true) => {
  const { specifiers, id } = market;
  const numSpecifier = _.isNaN(parseFloat(specifiers)) ? Number(specifiers.replace(/\D+/g, '')) : parseFloat(specifiers);

  if (_.isFinite(numSpecifier)) {
    return asc ? numSpecifier : -1 * numSpecifier;
  }
  return id;
};

export const numGoalSort = ({ specifiers: a }, { specifiers: b }) => {
  if (isNaN(a) || isNaN(b)) {
    if (a.includes('No Goal')) return -1;
    if (b.includes('No Goal')) return 1;

    if (a.includes('More')) return 1;
    if (b.includes('More')) return -1;
    return 1;
  }
  a = +a;
  b = +b;
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};
