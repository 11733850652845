import {
  ApiManager,
  Bus,
  logger,
  SettingsManager,
  SiteConfigManager,
  useApplicationState,
  useSettings,
} from '@apollo/core';
import React, { useMemo, useRef, useState } from 'react';
import { LiveChatWidget } from '@livechat/widget-react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { appEvents, defaultHeaderKeys } from '../constants';

const CordovaProvider = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const { buildMode, isCordova, platform, allowTracking, gtm, googleAPIKey, countryWhitelist } = useSettings();
  const { layout } = useApplicationState();
  const [deviceReady, _setDeviceReady] = useState(undefined);
  const [country, setCountry] = useState(undefined);
  const geoWatchRef = useRef(undefined);

  const cordovaDev = useMemo(
    () => isCordova && buildMode && buildMode.toLowerCase() !== 'production',
    [isCordova, buildMode],
  );

  const liveChatConfig = SiteConfigManager.getConfig(
    isCordova || layout.mobileDevice || layout.tabletDevice
      ? 'config.config.support.live_chat.mobile'
      : 'config.config.support.live_chat.desktop',
  );

  const isIOS = useMemo(
    () => isCordova && platform?.toUpperCase() === 'IOS',
    [isCordova, platform],
  );

  const deviceReadyRef = React.useRef(deviceReady);
  const setDeviceReady = (data) => {
    deviceReadyRef.current = data;
    _setDeviceReady(data);
  };

  const onDevicePause = () => {
    logger.debug('Cordova device pause.', deviceReadyRef.current);
    Bus.emit(appEvents.devicePause);
  };

  const onDeviceResume = () => {
    logger.debug('Cordova device resume.', deviceReadyRef.current);
    if (!deviceReadyRef.current) {
      return;
    }
    Bus.emit(appEvents.deviceResume);
  };

  const onDeviceOffline = () => {
    logger.debug('Cordova device offline.', deviceReadyRef.current);
    Bus.emit(appEvents.deviceNetworkOffline);
  };

  const onDeviceOnline = () => {
    logger.debug('Cordova device online.', deviceReadyRef.current);
    if (!deviceReadyRef.current) {
      return;
    }
    Bus.emit(appEvents.deviceNetworkOnline);
  };

  const onDeviceReady = () => {
    document.removeEventListener('deviceready', onDeviceReady, false);

    const { cordova, device } = window;
    if (!cordova) {
      return;
    }
    logger.debug('CORDOVA BUILD');

    if (device) {
      const { platform, model, uuid } = device;
      ApiManager.setDefaultHeaders({
        ...ApiManager.getDefaultHeaders(),
        [defaultHeaderKeys.LocalPlatform]: platform,
      });
      SettingsManager.updateSettings({
        platform,
      });
      logger.debug('CORDOVA DEVICE', platform, model, uuid);
    }

    /*
     * May need to also perform IP look-up/restriction
     * Ref: https://www.geoplugin.com/examples
     */
    const geoBlock = (geoCountry, geoError = null) => {
      if (geoCountry && geoCountry === country) {
        return;
      }
      const whiteList = cordovaDev
        ? ['Australia', 'New Zealand', 'Belarus', 'Poland', 'Philippines']
        : countryWhitelist;
      const regex = new RegExp((whiteList || []).join('|'), 'i');
      if (!geoCountry || !regex.test(geoCountry)) {
        SettingsManager.updateSettings({
          clientBlocked: true,
          country: geoCountry,
          geoError,
        });
      } else {
        SettingsManager.updateSettings({
          clientBlocked: false,
          country: geoCountry,
          geoError,
        });
      }
      setCountry(geoCountry);
    };

    const onSuccess = (position) => {
      logger.debug('GeoLocation', position);
      const geoLocateUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=${googleAPIKey}`;
      fetch(geoLocateUrl, {})
        .then((response) => response.json())
        .then((response) => {
          let geoCountry = '';
          if (response.results && response.results) {
            for (let i = 0, l = response.results.length; i < l; i += 1) {
              if (response.results[i].types.includes('country')) {
                geoCountry = response.results[i].formatted_address;
                break;
              }
            }
          }
          logger.info('Google Maps', geoCountry, response);
          geoBlock(geoCountry);
        })
        .catch((e) => {
          logger.error(e);
          geoBlock(null);
        });
    };

    const onError = (error) => {
      logger.error('GeoLocation Error', error);
      geoBlock(null, error);
    };

    const idfaPlugin = cordova.plugins.idfa;
    idfaPlugin
      .getInfo()
      .then((info) => {
        logger.debug('ATT Permissions', info);
        if (
          !info.trackingLimited
          || info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED
        ) {
          return info.idfa || info.aaid;
        }
        if (info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
          return idfaPlugin.requestPermission().then((result) => {
            if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
              return idfaPlugin.getInfo().then((info) => info.idfa || info.aaid);
            }
          });
        }
      })
      .then((idfaOrAaid) => {
        if (idfaOrAaid) {
          logger.debug('Platform tracking id: ', idfaOrAaid);
          SettingsManager.updateSettings({
            allowTracking: true,
            trackingId: idfaOrAaid,
          });
        } else {
          logger.warn('Platform tracking denied: ', idfaOrAaid);
        }
      });

    window.open = cordova.InAppBrowser.open;

    navigator.geolocation.getCurrentPosition(onSuccess, onError);

    // todo: Updates are too frequent. GMaps needs a delay to prevent hitting billing thresholds.
    // geoWatchRef.current = navigator.geolocation.watchPosition(onSuccess, onError, {
    //   maximumAge: 3000,
    //   timeout: 5000,
    //   enableHighAccuracy: false,
    // });

    document.addEventListener('pause', onDevicePause, false);
    document.addEventListener('resume', onDeviceResume, false);
    document.addEventListener('offline', onDeviceOffline, false);
    document.addEventListener('online', onDeviceOnline, false);
    setDeviceReady(true);
  };

  React.useEffect(() => {
    document.addEventListener('deviceready', onDeviceReady, false);
  }, [isCordova]);

  React.useEffect(() => {
    if (!gtm) {
      return;
    }
    if (!isCordova || (isCordova && allowTracking)) {
      ReactGA.initialize(gtm);
    }
  }, [isCordova, allowTracking, gtm]);

  React.useEffect(() => {
    const pageTitle = (pathname || 'Home').replace('/', ' ');
    ReactGA.send({ hitType: 'pageview', page: pathname, title: pageTitle });
    // console.log('SEND PATHNAME', pathname);
  }, [pathname]);

  const setLiveChat = React.useCallback(({ visibility }) => {
    Bus.send({
      event: Bus.events.layout.setLiveChat,
      data: visibility,
    });
  }, []);

  return (
    <>
      {children}
      {!isIOS // iOS App does not work. Bug inside LiveChat iFrame
        && liveChatConfig?.is_available
        && layout.liveChat && ( // should be 'hidden' by default
          <LiveChatWidget
            license={liveChatConfig?.widget_id}
            visibility={layout.liveChat}
            onVisibilityChanged={(e) => setLiveChat(e)}
            group={liveChatConfig?.group_id}
            chatBetweenGroups={false}
          />
      )}
    </>
  );
};

export default CordovaProvider;
