import React, { useMemo } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import { getAvailablePages, SiteConfigManager } from '@apollo/core';
import { availableClientProfileRoutes, COMPONENT_TYPES } from '@apollo/routing';
import Label from '../../../shared/components/I18n/Label';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import { isDateFuture } from './BonusesTable/bonusesUtils';
import { BonusSelector } from '../../../state/Bonus/Bonus';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

export const ALL_TAB_NAME = 'bet-bonus-all';

const BonusesNavigation = ({ bonusTabs, activeTab }) => {
  const t = useTranslate();
  // const applicationState = useApplicationState();
  // const [bonuses] = at(applicationState, ['wallet.bonuses']);
  const bonuses = BonusSelector.selectAllBonuses();

  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);

  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);

  const tabs = useMemo(
    () => getAvailablePages(availableClientProfileRoutes, clientProfileTabs),
    [clientProfileTabs]
  );

  const bonusLink = useMemo(() => tabs?.find((t) => t.type === 'bonuses')?.link || '', [tabs]);

  const path = `${clientProfilePath}/${bonusLink}/:bonusType?`;

  const hasBonuses = !!bonuses?.filter((b) => isDateFuture(b.props?.expiry)).length;

  return hasBonuses ? (
    <div className='navigation navigation--secondary bonus-navigation'>
      <Scrollbars className='scroll__container'>
        {bonusTabs.map((tab) => (
          <NavLink
            key={tab}
            className={cx('bet-bonus--link', {
              active: activeTab === tab.toLowerCase(),
            })}
            to={generatePath(path, {
              bonusType: tab.toLowerCase(),
            })}
          >
            <div className='bet-bonus--icon'>
              <i className={`icons icon--${tab.toLowerCase()}`} title={t(tab)} />
            </div>
            <Label message={tab === ALL_TAB_NAME ? 'all_bonuses' : tab} />
            <span className='bet-bonus--counter'>
              {
                bonuses.filter(
                  (b) =>
                    (tab === ALL_TAB_NAME || b.bonusType === tab) && isDateFuture(b.props?.expiry)
                ).length
              }
            </span>
          </NavLink>
        ))}
      </Scrollbars>
    </div>
  ) : null;
};

export default BonusesNavigation;
