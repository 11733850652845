import React, { useMemo } from 'react';
import cx from 'classnames';
import { isEmpty, sortBy } from 'lodash';
import SportListItemHeader from '../../ResultsPage/SportList/SportListItemHeader';
import TournamentsListItem from '../SportsSidebar/Tournaments/TournamentsListItem';

const CategoryListItem = ({ category, tournaments, active, onToggleActiveCategory }) => {
  const sortedTournaments = useMemo(() => sortBy(tournaments, (t) => t.name), [tournaments]);

  if (isEmpty(tournaments)) {
    return null;
  }

  const className = cx('category-list-item bottom__block two', {
    active,
  });

  return (
    <>
      <SportListItemHeader
        className={className}
        sportName={category.name}
        toggleExpand={onToggleActiveCategory}
        active={active}
        id={category.id}
      />
      {active &&
        sortedTournaments.map((tournament) => (
          <TournamentsListItem key={tournament.id} tournament={tournament} />
        ))}
    </>
  );
};

export default CategoryListItem;
