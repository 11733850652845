import React, { useEffect, useMemo } from 'react';
import { createBetHistoryState } from '@apollo/core';
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import { BET_STATUSES } from '@apollo/core/src/constants';
import Svg from '../../../../shared/components/svg/Svg';
import Label from '../../../../shared/components/I18n/Label';
import MyBetsFilter from '../../../Betslip/MyBets/MyBetsFilter';
import MyBetsItem from '../../../Betslip/MyBets/MyBetsItem';
import Pagination from '../../../../shared/components/Pagination';
import { prepareBetSlipRunners } from '../../../../core/utils';
import Loader from '../../../../shared/components/Loader';

const { UNSETTLED } = BET_STATUSES;

const BetHistoryMobile = () => {
  const {
    isLoading,
    onSelectBetStatus,
    selectedBetStatus,
    bets,
    totalPages,
    currentPage,
    setCurrentPage,
  } = createBetHistoryState({ betStatus: UNSETTLED });

  useEffect(() => {
    prepareBetSlipRunners(bets);
  }, [bets, currentPage]);

  const betGroupByDate = useMemo(
    () =>
      bets?.reduce((acc, bet) => {
        const { createTime } = bet;
        const date = moment(createTime).format('YYYY-MM-DD');
        if (acc[date]) {
          acc[date].push(bet);
        } else {
          acc[date] = [bet];
        }
        return acc;
      }, {}),
    [bets]
  );

  const content = useMemo(() => {
    if (isLoading) {
      return (
        <div className='bets__myBets'>
          <div className='bets__myBets__empty'>
            <Loader />
          </div>
        </div>
      );
    }

    if (isEmpty(bets)) {
      return (
        <div className='empty-state-history'>
          <div className='svg-container'>
            <Svg name='emptyState' />
          </div>
          <span>
            <Label message='There is no items yet' />
          </span>
        </div>
      );
    }

    return map(Object.keys(betGroupByDate), (date) => (
      <div key={date}>
        <div className='bet_date'>{moment(date).format('dddd, DD MMM')}</div>
        <div>
          {map(betGroupByDate[date], (bet) => (
            <MyBetsItem key={bet.betId} bet={bet} collapsedMulti />
          ))}
        </div>
      </div>
    ));
  }, [isLoading, bets, betGroupByDate]);

  return (
    <div className='history-table'>
      <div className='tab__content active'>
        <MyBetsFilter onSelectBetStatus={onSelectBetStatus} selectedBetStatus={selectedBetStatus} />
        <div className='bets__myBets'>{content}</div>
      </div>
      {totalPages > 1 && (
        <Pagination
          pages={totalPages}
          currentPage={currentPage}
          changeCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default BetHistoryMobile;
