import React, { useMemo } from 'react';
import {
  BETSLIP_VIOLATION,
  centsToDecimal,
  constants,
  getBetItemsForMulti,
  useApplicationState,
  useBetslipState,
  useBetslipStateUpdate,
  useObservableOddsContext,
} from '@apollo/core';
import { isEmpty, isNumber } from 'lodash';
import { BONUS_TYPE } from '@apollo/core/src/constants';
import BetList from './BetList';
import Label from '../../../shared/components/I18n/Label';
import { SPECIAL_MARKETS, sportProviders } from '../../../core/constants';
import PossibleWinSection from '../BetslipDefaultView/PossibleWinSection';
import Odds from '../../../shared/components/Odds/Odds';
import useTranslate from '../../../shared/components/I18n/Interpreter';
import TotalStakeSection from '../BetslipDefaultView/TotalStakeSection';
import MoneyInputBlock from './MoneyInputBlock';
import BetBonusButtons from './BetBonusButtons';
import { getCurrencySymbol } from '../../../core/utils';

const BetMulti = () => {
  const {
    betTypes,
    amount,
    isPossibleMulti,
    confirming,
    suspended,
    error,
    active,
    feedOffline,
    errors,
    betItems,
    bonuses,
    bonusAssignments,
  } = useBetslipState();
  const t = useTranslate();

  const { hasUnacceptedOdds, odds: observableOdds } = useObservableOddsContext();

  // const betItems = getSingleBetItems();
  // const notExoticBetItems = getNotExoticBetItems(betItems);
  const filteredBetItems = useMemo(
    () => getBetItemsForMulti(betItems),

    [betItems]
  );

  const betslipStateUpdate = useBetslipStateUpdate();
  const {
    account: { currency },
  } = useApplicationState();

  const currencySymbol = getCurrencySymbol(currency);

  const multiBet = betTypes.find((betType) => betType.betType === constants.BET_TYPE.MULTIPLE);

  const oddsToDisplay = multiBet?.oddsToDisplay || multiBet?.odds;

  const assignedBonus = useMemo(() => {
    const bonusAssignment = (bonusAssignments || []).find(
      (it) => it.requestId === multiBet?.requestId
    );
    return bonusAssignment
      ? ((bonuses || {})[multiBet?.requestId] || []).find(
          (bonus) => bonus.id === bonusAssignment.bonusId
        )
      : null;
  }, [bonusAssignments, bonuses, multiBet]);

  const possibleWin = oddsToDisplay && amount && oddsToDisplay * amount;

  const initialOdds = useMemo(() => {
    let odds = 1;
    if (!isEmpty(observableOdds) && multiBet) {
      multiBet?.outcomes?.forEach((outcome) => {
        if (isNumber(observableOdds[outcome.outcomeId])) {
          odds *= observableOdds[outcome.outcomeId];
        }
      });
    }
    return odds;
  }, [observableOdds, multiBet]);

  const handleBetAmountChange = React.useCallback(
    (amount) => betslipStateUpdate({ amount }),
    [betslipStateUpdate]
  );

  const betError = React.useMemo(
    () =>
      multiBet && !isEmpty(errors)
        ? errors.find((err) => err.requestId === multiBet.requestId)
        : null,
    [errors, multiBet]
  );

  const isTbd = React.useMemo(
    () => filteredBetItems.some((item) => SPECIAL_MARKETS[item.marketTypeId]),
    [filteredBetItems]
  );

  const hasNote = React.useMemo(
    () => filteredBetItems.some((item) => item.providerName === sportProviders.Sportcast),
    [filteredBetItems]
  );

  const isOddsChanged = React.useMemo(() => {
    const isChanged =
      betError &&
      betError.violations &&
      betError.violations[0]?.code === BETSLIP_VIOLATION.OUTCOME_ODDS_CHANGED;
    return isChanged;
  }, [betError]);

  return isPossibleMulti && multiBet && !(confirming && !amount) ? (
    <div className='multi-bet'>
      {betError && (
        <div className={`betError ${isOddsChanged ? 'warning' : ''}`}>
          {isOddsChanged && <i className='AkrIcon-warning' />}
          <Label
            className='error'
            params={betError.violations[0]?.violationInfo}
            message={`betslip_error_${betError.violations[0]?.code}`}
          />
        </div>
      )}

      <div
        className={`itemList__item ${
          // eslint-disable-next-line no-nested-ternary
          isOddsChanged ? 'warning' : betError ? 'error' : ''
        }`}
      >
        {/* <div>&nbsp;</div> */}
        <div className='event event--multi'>
          <div className='event__outcome-name'>
            <span className='icon-racing icon-leg-multi' title={t('Leg Multi')} />
            {filteredBetItems.length} {t('Leg Multi')}
          </div>
        </div>
        <div
          className={`odd ${
            hasUnacceptedOdds && isOddsChanged && multiBet?.odds !== initialOdds ? 'down' : ''
          }
          ${oddsToDisplay > multiBet?.odds ? 'boosted' : ''}`}
        >
          {isTbd ? (
            <Label message='tbd' />
          ) : multiBet?.odds !== oddsToDisplay ? (
            <>
              <div className='odd__default'>
                <i
                  className={`icons icon--${BONUS_TYPE.ODDS_BOOST.toLowerCase()}`}
                  title={t(BONUS_TYPE.ODDS_BOOST)}
                />
                <Odds value={oddsToDisplay} decimal={3} minDecimal={2} />
              </div>
              <div className='odd__changed'>
                <span>
                  <Odds value={multiBet.odds} decimal={3} minDecimal={2} />
                </span>
              </div>
            </>
          ) : hasUnacceptedOdds && isOddsChanged && multiBet?.odds !== initialOdds ? (
            <>
              <div className='odd__default'>
                <span>
                  <Odds value={initialOdds} decimal={3} minDecimal={2} />
                </span>
              </div>

              <div className='odd__changed'>
                <span className='odd__changed__icon' />
                <Odds value={multiBet.odds} decimal={3} minDecimal={2} />
              </div>
            </>
          ) : (
            <Odds value={oddsToDisplay} decimal={3} minDecimal={1} />
          )}
        </div>
        {confirming ? (
          <>
            <div className='controls__block'>
              <TotalStakeSection
                currencySymbol={currencySymbol}
                totalStake={centsToDecimal(amount)}
                label='stake'
              />
            </div>
            <div className='controls__block'>
              {assignedBonus ? (
                <div className='bonus-type'>
                  <i
                    className={`icons icon--${assignedBonus.bonusType.toLowerCase()}`}
                    title={t(assignedBonus.bonusType)}
                  />
                  <Label message={assignedBonus.bonusType} className='bonus__description' />
                </div>
              ) : null}
              <PossibleWinSection
                possibleWin={possibleWin}
                currencySymbol={currencySymbol}
                isTbd={isTbd}
              />
            </div>
          </>
        ) : (
          <>
            <div className='b-row controls__block'>
              <div className='b-col' />
              <div className='b-col'>
                <MoneyInputBlock
                  amount={amount}
                  bonuses={bonuses[multiBet.requestId]}
                  assignedBonus={assignedBonus}
                  disabled={suspended || feedOffline || !active || error}
                  handleBetAmountChange={handleBetAmountChange}
                />
              </div>
            </div>

            <BetBonusButtons
              requestId={multiBet.requestId}
              bonuses={bonuses[multiBet.requestId]}
              handleBetAmountChange={handleBetAmountChange}
            />

            <div className='b-row controls__block'>
              <div className='b-col' />
              <div className='b-col'>
                <PossibleWinSection
                  possibleWin={possibleWin}
                  currencySymbol={currencySymbol}
                  isTbd={isTbd}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {hasNote ? (
        <div className='itemList__item__note'>
          <div className='itemList__item__note--header'>
            <Label message='note header' />
          </div>
          <div className='itemList__item__note--body'>
            <Label message='note body sportcast' />
          </div>
        </div>
      ) : null}
      <div className='itemList_sub-container'>
        <BetList readonly betItems={filteredBetItems} />
      </div>
    </div>
  ) : (
    <div className='itemList__item no-multi'>
      <p className='no-multi-header'>
        <span className='icon-racing icon-leg-multi' title={t('Leg Multi')} />
        <Label message='multiple_bet' />
      </p>
      <Label message='no_multiple_bet' />
    </div>
  );
};

export default BetMulti;
