import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { filter, forEach, includes, isArray, isEmpty, toNumber } from 'lodash';
import {
  Bus,
  isMarketTypeVisible,
  isVisible,
  SiteConfigManager,
  usePreferences,
} from '@apollo/core';
import { SportSelector } from '@apollo/core/src/state/sport/sport';
import './DetailPageMarketTypesMapping.scss';
import { marketGroupTypes, sportBetTypes } from '../../../core/constants';
import DetailsMarketType from './DetailsMarketType/DetailsMarketType';
import SectionOdds from './Sgm/SectionOdds';
import Accordion from '../../../shared/components/Accordion/Accordion';
import SportLoader from '../SportLoader';
import useTranslate from '../../../shared/components/I18n/Interpreter';

const DetailPageMarketTypesMapping = ({ suspended, disabled, betType, marketGroups }) => {
  const enableSameGameMultiBets = SiteConfigManager.getConfig(
    'config.config.sportsbook.enableSameGameMultiBets'
  );

  const t = useTranslate();
  const loading = SportSelector.selectIsLoading();

  // save market show status
  const localStorageData = useMemo(() => {
    try {
      return JSON.parse(localStorage.getItem('marketTypesState') || {});
    } catch (e) {
      return {};
    }
  }, []);
  const { favoriteMarketTypes: favoriteMarketTypesIds = [] } = usePreferences();
  const [marketTypesShowState, setMarketTypesShowState] = useState(localStorageData);
  const [marketTypesFetched, setMarketTypesFetched] = useState(['MAIN']);

  useEffect(() => {
    localStorage.setItem('marketTypesState', JSON.stringify(marketTypesShowState));
  }, [marketTypesShowState]);

  const event = SportSelector.selectCurrentEvent();

  const isSgmMode = useMemo(
    () => enableSameGameMultiBets && betType === sportBetTypes.SameGameMulti,
    [enableSameGameMultiBets, betType]
  );

  const marketTypeEnabledForBetType = useCallback(
    (marketType) => {
      // Legacy test: Remove in future version
      console.log('marketType', marketType.singleEnabled, marketType.sgmEnabled);
      if (marketType.singleEnabled === undefined) {
        return true;
      }
      switch (betType) {
        case sportBetTypes.Single:
          return marketType.singleEnabled;
        case sportBetTypes.SameGameMulti:
          return marketType.sgmEnabled;
        default:
          return false;
      }
    },
    [betType]
  );

  const filteredMarketTypes = useMemo(() => {
    const filteredMarketTypes = {};
    forEach(event?.marketTypes, (mt, mtId) => {
      if (!isVisible(mt) || !marketTypeEnabledForBetType(mt)) {
        return;
      }

      let filteredMarkets = [];
      forEach(mt.markets, (m) => {
        const outcomes = m?.outcomes ? Object.values(m.outcomes) : [];
        if (!isVisible(m) || isEmpty(outcomes)) {
          if (!m?.outcomes) {
            console.warn('Invisible or missing outcomes.', m);
          }
          return;
        }

        let filteredOutcomes = [];
        switch (betType) {
          case sportBetTypes.SameGameMulti:
            filteredOutcomes = outcomes.reduce((list, item) => {
              if (!isVisible(item) || !item.isSgmEnabled) {
                return list;
              }
              return [
                ...list,
                {
                  ...item,
                  marketId: m.id,
                },
              ];
            }, []);
            break;

          case sportBetTypes.Single:
          default:
            filteredOutcomes = outcomes.reduce((list, item) => {
              if (!isVisible(item)) {
                return list;
              }
              return [
                ...list,
                {
                  ...item,
                  marketId: m.id,
                },
              ];
            }, []);
            break;
        }

        if (isEmpty(filteredOutcomes)) {
          return;
        }

        filteredMarkets = [
          ...filteredMarkets,
          {
            ...m,
            outcomes: filteredOutcomes,
          },
        ];
      });

      if (isEmpty(filteredMarkets)) {
        return;
      }

      filteredMarketTypes[mtId] = {
        ...mt,
        // Special prop used in child components
        filteredMarkets,
      };
    });

    return filteredMarketTypes;
  }, [event, marketTypeEnabledForBetType, betType]);

  const getProcessedMarketTypes = React.useCallback(
    (group, type) => {
      // show all visible markets by default
      let filterFunction = (marketType) => isMarketTypeVisible(marketType);

      if (type === marketGroupTypes.marketGroup) {
        // sorting function for market group
        filterFunction = (marketType) => {
          if (!isMarketTypeVisible(marketType)) {
            return false;
          }

          // if group is null then show all markets
          if (!group) {
            return true;
          }

          if (!marketType.groups) {
            console.warn('SGM Market error:', marketType);
            return false;
          }

          // todo: find out how/why this happens
          if (!isArray(marketType.groups)) {
            console.error('marketType.groups is not an array', marketType.groups);
            return false;
          }

          return marketType.groups.includes(group);
        };
      } else if (type === marketGroupTypes.betGroup) {
        // sorting function for bet group
        // eslint-disable-next-line max-len
        filterFunction = (marketType) =>
          isMarketTypeVisible(marketType) && toNumber(marketType.betGroup) === toNumber(group);
      }

      const favoriteMarketTypes = filter(
        filteredMarketTypes,
        ({ id }) => favoriteMarketTypesIds.indexOf(id) !== -1
      ).sort((a, b) => favoriteMarketTypesIds.indexOf(a.id) - favoriteMarketTypesIds.indexOf(b.id));

      const regularMarketTypes = filter(
        filteredMarketTypes,
        ({ id }) => favoriteMarketTypesIds.indexOf(id) === -1
      ).sort((a, b) => {
        if (a.orderPosition === null) {
          return 1;
        }

        if (b.orderPosition === null) {
          return -1;
        }

        const iA = Number(a.orderPosition);
        const iB = Number(b.orderPosition);
        if (iA > iB) {
          return 1;
        }
        if (iA < iB) {
          return -1;
        }

        return 0;
      });

      return [...favoriteMarketTypes, ...regularMarketTypes].filter(filterFunction);
    },
    [filteredMarketTypes, favoriteMarketTypesIds]
  );

  const loadMarketGroupData = React.useCallback(
    (marketGroup) => {
      if (!marketTypesFetched.includes(marketGroup)) {
        const newMarketTypesFetched = [...marketTypesFetched, marketGroup];

        Bus.emit(Bus.events.sport.sportLoadEventMarketGroup, {
          eventId: event.id,
          marketGroup,
        });
        setMarketTypesFetched(newMarketTypesFetched);
      }
    },
    [event?.id, marketTypesFetched]
  );

  const renderMarketGroup = (marketGroup) => {
    // Legacy test: Remove in future version
    if (marketGroup.singleEnabledMarketsCount !== undefined) {
      if (!isSgmMode && marketGroup.singleEnabledMarketsCount === 0) {
        return null;
      }
    }

    if (isSgmMode && marketGroup.sgmEnabledMarketsCount === 0) {
      return null;
    }

    const processedMarketTypes = getProcessedMarketTypes(
      marketGroup.value?.group,
      marketGroup.value?.type
    );

    return (
      <Accordion
        title={marketGroup.text}
        className={`eventView__market-group eventView__market-group--${marketGroup.value?.group}`}
        key={marketGroup.value?.group}
        collapsed={marketGroup.value?.group !== 'MAIN'}
        onToggle={() => loadMarketGroupData(marketGroup.value?.group)}
        subTitle={`${isSgmMode ? marketGroup.sgmEnabledMarketsCount : marketGroup.singleEnabledMarketsCount} ${t('markets')}`}
      >
        {isEmpty(processedMarketTypes) && loading ? <SportLoader /> : null}
        {processedMarketTypes.map((marketType) => (
          <DetailsMarketType
            key={marketType.id}
            marketType={marketType}
            event={event}
            suspended={suspended}
            disabled={disabled}
            isFavorite={includes(favoriteMarketTypesIds, marketType.id)}
            marketTypesShowState={marketTypesShowState}
            setMarketTypesShowState={setMarketTypesShowState}
            isSgm={isSgmMode}
            activeGroup={marketGroup}
          />
        ))}
      </Accordion>
    );
  };

  if (!isVisible(event)) {
    return null;
  }

  return (
    <div className='eventView__container'>
      <div className='eventView'>{marketGroups.map((group) => renderMarketGroup(group))}</div>
      {isSgmMode && <SectionOdds event={event} />}
    </div>
  );
};

export default DetailPageMarketTypesMapping;
