import { useRacingState } from '@apollo/core/src/state/racing/racing';
import useTranslate from '../I18n/Interpreter';

export const RacingMarketTypeName = ({ marketType }) => {
  const [racingState] = useRacingState();
  const t = useTranslate();
  const { marketTypes } = racingState;

  const marketTypeInfo = marketTypes.find((market) => market.type === marketType);

  return marketTypeInfo?.shortName || marketTypeInfo?.name || t(marketType);
};
