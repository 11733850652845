import React from 'react';
import { isTBD } from '../../../../../core/utils';
import Label from '../../../../../shared/components/I18n/Label';
import Odds from '../../../../../shared/components/Odds';

const TransactionOdds = ({ outcomes, betOdds, originalBetOdds, defaultOdds = '-' }) => {
  if (!betOdds) {
    return defaultOdds;
  }

  if (isTBD({ outcomes }) || betOdds <= 1) {
    return <Label message='tbd' />;
  }

  if (originalBetOdds) {
    return (
      <div className='odd boosted'>
        <div className='odd__default'>
          <span>
            <Odds value={betOdds} decimal={3} minDecimal={1} />
          </span>
        </div>
        <div className='odd__changed'>
          <span>
            <Odds value={originalBetOdds} decimal={3} minDecimal={1} />
          </span>
        </div>
      </div>
    );
  }

  return <Odds value={betOdds} decimal={3} minDecimal={1} />;
};

export default TransactionOdds;
