// Self Exclusion
import { centsToDecimal, DateService, ResponsibleApi } from '@apollo/core';
import _, { isEmpty, isFinite, reduce } from 'lodash';
import React from 'react';
import { handleFormSubmit } from '../../../../core/utils';
import Label from '../../I18n/Label';
import ToastManager from '../../../../core/ToastManager';

const DEPOSIT_LIMIT_KEYS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const selfExclusionForm = {
  name: 'selfExclusion',
  fields: [
    {
      component: 'SELECT',
      number: 0,
      name: 'period',
      label: 'self_exclusion_period',
      text: 'Exclusion period',
      required: true,
      // value: 'DAYS_3',
      options: [
        {
          text: '3 day',
          value: 'DAYS_3',
        },
        {
          text: '7 days',
          value: 'DAYS_7',
        },
        {
          text: '30 days',
          value: 'DAYS_30',
        },
        // {
        //   text: 'forever',
        //   value: 'FOREVER',
        // },
      ],
      rules: [
        {
          type: 'REQUIRED',
          message: 'required',
        },
      ],
    },
  ],
};

// Reality Check
export const realityCheckForm = {
  name: 'realityCheck',
  fields: [
    {
      component: 'SELECT',
      name: 'period',
      text: 'Check period',
      options: [
        {
          text: '1 min',
          value: 'MINUTE_1',
        },
        {
          text: '5 min',
          value: 'MINUTE_5',
        },
        {
          text: '10 min',
          value: 'MINUTE_10',
        },
        {
          text: '20 min',
          value: 'MINUTE_20',
        },
        {
          text: '30 min',
          value: 'MINUTE_30',
        },
        {
          text: '1 hour',
          value: 'HOUR_1',
        },
        {
          text: '2 hour',
          value: 'HOUR_2',
        },
        {
          text: '4 hour',
          value: 'HOUR_4',
        },
        {
          text: '6 hour',
          value: 'HOUR_6',
        },
        {
          text: 'Do Not Remind',
          value: 'NEVER',
        },
      ],
      rules: [],
    },
  ],
};

// responsible gaming limits
export const getLimitWarning = (data) => {
  if (!data) {
    return null;
  }

  const { value, dateFrom } = data;

  return {
    message: value ? 'confirm_pending_order' : 'limits_edit_nullify',
    params: {
      value: value && centsToDecimal(value),
      date: DateService.getFormattedDateWithTime(dateFrom),
    },
  };
};

export const showLimitsWereEditedNotification = ({ type, limits, currencySymbol, translate }) => {
  const renderHeader = (
    <Label
      className='toast-limits-edited__label'
      message='limits_were_edited'
      params={{ type: translate(type) }}
    />
  );
  const renderLimits = _.map(limits, ({ type: limitType, value }) => (
    <React.Fragment key={limitType}>
      <br />
      <Label
        className='toast-limits-edited__limit'
        message='limit_type_value_edited'
        params={{
          type: translate(limitType),
          value: `${currencySymbol}${(value / 100).toFixed(2)}`,
        }}
      />
    </React.Fragment>
  ));

  return ToastManager.success(
    <div className='toast__limits-edited'>
      {renderHeader}
      {renderLimits}
    </div>,
  );
};

export const getSelfRegulationLimitsFromFields = (fields, spentType) => reduce(
  fields,
  (acc, value, key) => {
    const parsedValue = value == null ? 0 : Number.parseFloat(value);

    if (!isFinite(parsedValue)) {
      return acc;
    }

    const item = {
      type: key.toUpperCase(),
      value: parsedValue,
    };

    if (spentType) {
      item.spentType = spentType;
    }

    return [...acc, item];
  },
  [],
);

export const getSelfRegulationFormattedFields = (spentType, formPayload) => {
  const limits = getSelfRegulationLimitsFromFields(formPayload, spentType);

  if (isEmpty(limits)) {
    return null;
  }

  return { limits };
};

export const onSetRealityCheckSubmit = handleFormSubmit(
  ResponsibleApi.setRealityCheckSettings.bind(ResponsibleApi),
);

// Self-regulation Loss Limits
export const onSetLostLimitsSubmit = async (formData, actions, spentType) => handleFormSubmit(
  ResponsibleApi.setClientLossLimits.bind(ResponsibleApi),
  getSelfRegulationFormattedFields.bind(null, spentType),
)(formData, actions);

// Self-regulation Deposit limits
export const onSetDepositLimitsSubmit = handleFormSubmit(
  ResponsibleApi.setClientDepositLimits.bind(ResponsibleApi),
  getSelfRegulationFormattedFields.bind(null, null),
);
