import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@apollo/core';

import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import ENG from 'react-phone-number-input/locale/en';
// import RUS from 'react-phone-number-input/locale/ru';
// import ESP from 'react-phone-number-input/locale/es';
// import DEU from 'react-phone-number-input/locale/de';
// import UKR from 'react-phone-number-input/locale/ua';
import { isEmpty } from 'lodash';
import Label from '../../../I18n/Label';
import { getIsFieldRequired } from '../../../../../core/utils';
import ErrorBlock from '../../Input/ErrorBlock';

const langMap = {
  // RUS,
  ENG,
  // ESP,
  // DEU,
  // UKR,
};

const PhoneField = (props) => {
  const { lang } = useSettings();

  const { field, onChange, onFocus, onBlur } = props;

  const {
    value = '+61',
    autofocus,
    disabled,
    label,
    placeholder,
    name,
    rules,
    touched,
    errors,
  } = field;

  const [phoneInputValue, setPhoneInputValue] = useState(value || '');
  const [error, setError] = useState('');

  const changeHandler = useCallback(
    (value) => {
      onChange(value);
      setPhoneInputValue(value);
    },
    [onChange]
  );

  const isValidPhone = useMemo(
    () => isValidPhoneNumber(String(phoneInputValue || '')),
    [isValidPhoneNumber, phoneInputValue]
  );

  useEffect(() => {
    if (touched) {
      if (!isValidPhone && phoneInputValue) {
        setError('wrong_phone_input');
      } else if (!isEmpty(errors)) {
        setError(errors[0]);
      } else {
        setError(null);
      }
    } else {
      setError(null);
    }
  }, [errors, touched, isValidPhone, phoneInputValue]);

  const success = touched && !error && !errors[0];
  const hasError = touched && (error || errors[0]);

  return (
    <div className='block block--phone'>
      <div className='ui__title'>
        <Label message={label} />
        {getIsFieldRequired(rules) && <span>*</span>}
      </div>
      <div className='block__wrap'>
        <PhoneInput
          placeholder={placeholder}
          value={phoneInputValue}
          onChange={changeHandler}
          labels={langMap[lang]}
          limitMaxLength
          touched={touched}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          name={name}
          className={`${success ? 'success' : ''} ${hasError ? 'error' : ''}`}
          autoFocus={autofocus}
          international
          addInternationalOption={false}
          defaultCountry='AU'
          countries={['AU', 'NZ']}
          withCountryCallingCode
        />
        <ErrorBlock withTranslate error={hasError ? error || errors[0] : null} />
      </div>
    </div>
  );
};

export default PhoneField;
