import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment/moment';
import { getIsFieldRequired } from '../../../../../core/utils';
import Input from '../../Input/Input';

const dateRegex = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$/;

const DaypickerFieldNew = ({ field, onChange, onFocus, onBlur }) => {
  const {
    touched,
    value = '',
    placeholder,
    autofocus,
    errors,
    warnings,
    active,
    label,
    name,
    rules,
    disabled,
  } = field;

  const momentValue = useMemo(() => moment.utc(value), [value]);

  const [inputValue, setInputValue] = useState(
    momentValue.isValid() ? momentValue.format('DD/MM/YYYY') : '',
  );

  const [error, setError] = useState(null);

  const changeHandler = useCallback(
    (event) => {
      const { inputType } = event.nativeEvent;
      let val = event.target.value;
      if (inputType === 'deleteContentBackward') {
        setInputValue(val);
        return true;
      }

      if (val.length > 10) {
        return false;
      }

      val = val.replace(/\D/g, '');

      if (val.length >= 2 && val[2] !== '/') {
        val = `${val.slice(0, 2)}/${val.slice(2)}`;
      }

      if (val.length >= 5 && val[5] !== '/') {
        val = `${val.slice(0, 5)}/${val.slice(5)}`;
      }

      setInputValue(val);

      if (val.length === 10) {
        const newValue = moment.utc(val, 'DD/MM/YYYY');
        onChange(newValue.isValid() ? newValue.valueOf() : null);
      }
    },
    [onChange],
  );

  const blurHandler = useCallback(
    (event) => {
      if (!dateRegex.test(inputValue)) {
        setError('invalid_date');
      }
      onBlur(event);
    },
    [onBlur, inputValue],
  );

  useEffect(() => {
    if (touched && errors?.[0]) {
      setError(errors[0].message);
    } else {
      setError(null);
    }
  }, [touched, errors]);

  const warning = touched ? get(warnings, '[0].message') : null;
  const success = touched && !error;

  return (
    <Input
      type='tel'
      name={name}
      error={error}
      warning={warning}
      value={inputValue}
      placeholder={placeholder}
      onBlur={blurHandler}
      onFocus={onFocus}
      onChange={changeHandler}
      autoFocus={autofocus}
      success={success}
      isRequiredSymbol={getIsFieldRequired(rules)}
      touched={touched}
      active={active}
      label={label}
      disabled={disabled}
      withTranslate
    />
  );
};

export default DaypickerFieldNew;
