import React, { useCallback } from 'react';
import { get, isNull } from 'lodash';
import Input from '../../Input/Input';
import { getIsFieldRequired } from '../../../../../core/utils';

const TextField = ({
  field,
  onChange,
  onFocus,
  onBlur,
}) => {
  const {
    touched,
    value,
    autofocus,
    errors,
    warnings,
    active,
    label,
    placeholder,
    disabled,
    name,
    rules,
  } = field;
  const inputValue = isNull(value) ? '' : value;
  const changeHandler = useCallback((event) => onChange(event.target.value), [
    onChange,
  ]);
  const error = touched && errors[0];
  const warning = touched ? get(warnings, '[0].message') : null;
  const success = touched && !error;

  return (
    <Input
      type='password'
      name={name}
      error={error}
      warning={warning}
      value={inputValue}
      placeholder={placeholder}
      success={success}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={changeHandler}
      autoFocus={autofocus}
      touched={touched}
      active={active}
      disabled={disabled}
      label={label}
      isRequiredSymbol={getIsFieldRequired(rules)}
      withTranslate
    />
  );
};

export default TextField;
