import React, { useMemo } from 'react';

import {
  getSgmBetItems,
  getSingleBetItems,
  getSrmBetItems,
  logger,
  SiteConfigManager,
  useApplicationState,
  useBetslipState,
} from '@apollo/core';

import { BET_STATUSES, BET_TYPE } from '@apollo/core/src/constants';
import { isEmpty, isMatch } from 'lodash';
import cx from 'classnames';
import BetsErrors from '../BetsErrors';
import BetList from '../BetList/BetList';
import BetMulti from '../BetList/BetMulti';
import validateSelectedBet from '../validator';
import StakeAllBlock from './StakeAllBlock';
import Accordion from '../../../shared/components/Accordion/Accordion';
import Label from '../../../shared/components/I18n/Label';
import { prepareBetSlipRunners } from '../../../core/utils';
import OrderBetslip from '../OrderBetslip/OrderBetslip';

const BetslipDefaultView = () => {
  const enableMultiBets = SiteConfigManager.getConfig('config.config.sportsbook.enableMultiBets');

  const { authenticated } = useApplicationState();

  const {
    betItems,
    betTypes,
    confirming,
    betshash,
    settings,
    selectedBetType,
    isPossibleMulti,
    amount,
    fullfited,
    loading,
    active,
    // errors,
  } = useBetslipState();

  const { orderBet: isOrderBetAllowed } = settings;
  const { betsCount } = useApplicationState();

  const singleBetItems = getSingleBetItems();
  const srmBetItems = getSrmBetItems();
  const sgmBetItems = getSgmBetItems();

  const hasPendingBets = useMemo(() => !!betsCount[BET_STATUSES.PENDING_APPROVAL], [betsCount]);

  /*   const hasPendingBetError = useMemo(
    () => Object.values(errors || {}).some((err) => err.violations.some((v) => v.code === BETSLIP_VALIDATION_ERROR.PENDING_BET)),
    [errors],
  ); */

  const [selectedBetTypeIndex, setSelectedBetTypeIndex] = React.useState(0); // Default multiple

  // Auto reset side effect for selected bet type index
  React.useEffect(() => {
    if (!isEmpty(betTypes) && betTypes.length - 1 < selectedBetTypeIndex) {
      setSelectedBetTypeIndex(betTypes.length - 1);
    }
  }, [betTypes, selectedBetTypeIndex]);

  React.useEffect(() => {
    prepareBetSlipRunners(betItems);
  }, [betItems]);

  const isBetslipEmpty = isEmpty(betItems);

  // Selected bet type
  const selectedBet = React.useMemo(
    () => betTypes.find((betType) => betType.betType === selectedBetType) || betTypes[0] || {},
    [betTypes, selectedBetType]
  );

  // Storing prev bets hash to define when hash changed
  const [prevBetsValidation, setPrevBetsValidation] = React.useState({
    betshash: null,
    id: null,
  });

  React.useEffect(() => {
    if (!selectedBet.requestId) {
      // Canceling validation
      validateSelectedBet.cancel();

      // Resetting saved previous bets
      setPrevBetsValidation({
        betshash: null,
        id: null,
      });
    }
  }, [selectedBet.requestId]);

  // Handler that describes one selected bet validation
  // Invokes when selected bet ready, changed,
  // or betslip outcomes changed
  const betValidationHandler = React.useCallback(
    (selectedBet) => {
      // Resetting prev bets if no selected bet
      if (isEmpty(selectedBet)) {
        return;
      }

      const { id, requestId, odds } = selectedBet;

      // Skipping if bet not ready or its the same selected bet validation
      const actualOdds = selectedBet.betType === BET_TYPE.SINGLE || odds;
      if (
        !requestId ||
        !actualOdds ||
        isMatch(prevBetsValidation, {
          id,
          betshash,
        })
      ) {
        return;
      }

      logger.debug('validating selected set', selectedBet);
      // Invoking validation of selected bet
      validateSelectedBet({
        isPossibleMulti,
        betTypes,
        betItems,
        settings,
      });

      // Storing new betshash for selected bet & outcomes
      setPrevBetsValidation({
        betshash,
        id,
      });
    },
    [betshash, prevBetsValidation, settings, betItems, betTypes, isPossibleMulti]
  );

  // Betslip validation ready effect
  React.useEffect(() => {
    if (
      // validating only if authenticated
      authenticated &&
      // bets data fetched
      fullfited &&
      // bets active
      active &&
      // betslip not loading
      !loading &&
      // skip if some pending bet exists
      !hasPendingBets
    ) {
      // Invoking validation handler
      betValidationHandler(selectedBet);
    }
  }, [
    selectedBet,
    betValidationHandler,
    authenticated,
    fullfited,
    loading,
    active,
    hasPendingBets,
  ]);

  const singleCount = React.useMemo(
    () => (confirming ? singleBetItems.filter((bet) => bet.amount).length : singleBetItems?.length),
    [singleBetItems, confirming]
  );

  const srmCount = React.useMemo(
    () => (confirming ? srmBetItems.filter((bet) => bet.amount).length : srmBetItems?.length),
    [srmBetItems, confirming]
  );

  const sgmCount = React.useMemo(
    () => (confirming ? sgmBetItems.filter((bet) => bet.amount).length : sgmBetItems?.length),
    [sgmBetItems, confirming]
  );

  return (
    <>
      <div
        className={cx(
          'itemList',
          selectedBetType && `itemList--${selectedBetType.toLowerCase()}`,
          confirming && 'itemList--confirming'
        )}
      >
        {!isBetslipEmpty && (
          <>
            {singleCount >= 1 && (
              <Accordion
                className='itemList__bets--single'
                title={
                  <>
                    <Label message='singles' />
                    &nbsp;
                    <span className='bets-count'>{singleCount}</span>
                  </>
                }
              >
                <StakeAllBlock />
                <BetList betItems={singleBetItems} />
              </Accordion>
            )}
            {srmCount >= 1 && (
              <Accordion
                className='itemList__bets--srm'
                title={
                  <>
                    <Label message='same race multi' />
                    <span className='bets-count'>{srmCount}</span>
                  </>
                }
              >
                <BetList betItems={srmBetItems} />
              </Accordion>
            )}
            {sgmCount >= 1 && (
              <Accordion
                className='itemList__bets--sgm'
                title={
                  <>
                    <Label message='same game multi' />
                    <span className='bets-count'>{sgmCount}</span>
                  </>
                }
              >
                <BetList betItems={sgmBetItems} />
              </Accordion>
            )}
            {enableMultiBets && !(confirming && !amount) && (
              <Accordion
                className='itemList__bets--multiple'
                title={<Label message='multiple_bet' />}
              >
                <BetMulti />
              </Accordion>
            )}
          </>
        )}
      </div>
      <div className='controls sticky'>
        <BetsErrors />
        {!isBetslipEmpty && isOrderBetAllowed && (
          <div>
            <OrderBetslip betItems={betItems} selectedBet={selectedBet} disabled={loading} />
          </div>
        )}
      </div>
    </>
  );
};

export default BetslipDefaultView;
