import React from 'react';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import useTranslate from '../../components/I18n/Interpreter';
import Label from '../../components/I18n/Label';

const EmailVerification = () => {
  const t = useTranslate();
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isSuccessful = searchParams.get('isSuccessful') === 'true';

  const className = cx([
    'popup__emailVerification',
    isSuccessful && 'verification__successful',
  ]);

  return (
    <div className={className}>
      <div className='block'>
        <div className='block__title'>
          <div>
            {isSuccessful ? <span className='AkIcon-check-icon icon-container success' />
              : <span className='icon-container error'>!</span>}
            <Label message={isSuccessful ? 'verification_successful_title' : 'verification_unsuccessful_title'} />
          </div>
          <Link to={pathname}>
            <i className='icon__close' title={t('close')} />
          </Link>
        </div>
        <div className='block__content'>
          <div className='messages'>
            <Label message={isSuccessful ? 'verification_successful_message' : 'verification_unsuccessful_message'} />
          </div>
          <div className='block__content__button'>
            <Link to={pathname}>
              <div className='bBasePrimary'>
                <Label message='ok' />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
