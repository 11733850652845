import { useEffect, useMemo } from 'react';
import qs from 'query-string';
import { Bus } from '@apollo/core';
import { SportsActions, SportSelector, useSportState } from '@apollo/core/src/state/sport/sport';
import { useLocation, useParams } from 'react-router-dom';
import { isUrlNamesMatch } from '@apollo/routing/src/utils';
import { appEvents } from '../../core/constants';

const SportsPathHandler = (props) => {
  const { children } = props;

  const [sportsState, sportsDispatcher] = useSportState();
  const {
    selectedSportUrlName,
    selectedCategoryUrlName,
    selectedTournamentUrlName,
    selectedEventUrlName,
    selectedSportId,
    selectedCategoryId,
    selectedTournamentId,
    selectedEventId,
  } = sportsState;

  const routeParams = useParams();
  const { sportName, categoryName, tournamentName, eventName } = routeParams;

  const sports = SportSelector.selectSports();
  const categories = SportSelector.selectCategoriesBySportId(selectedSportId);
  const tournaments = SportSelector.selectTournamentsByCategoryId(selectedCategoryId);
  const events = SportSelector.selectEventsByTournamentId(selectedTournamentId);

  const { search } = useLocation();
  const selectedEventIdParam = useMemo(() => {
    const params = qs.parse(search);
    return params.e;
  }, [search]);

  useEffect(() => {
    let sportId = selectedSportId;
    if (!sportId || sportName !== selectedSportUrlName) {
      if (sportName) {
        const sport = sports.find((s) => isUrlNamesMatch(s.urlName, sportName));
        sportId = sport ? sport.id : null;
      }
    }

    let categoryId = selectedCategoryId;
    if (!categoryId || categoryName !== selectedCategoryUrlName) {
      if (categoryName) {
        const category = categories.find((c) => isUrlNamesMatch(c.urlName, categoryName));
        categoryId = category ? category.id : null;
      }
    }

    let tournamentId = selectedTournamentId;
    if (!tournamentId || tournamentName !== selectedTournamentUrlName) {
      if (tournamentName) {
        const tournament = tournaments.find((t) => isUrlNamesMatch(t.urlName, tournamentName));
        tournamentId = tournament ? tournament.id : null;
      }
    }

    // let eventId = selectedEventId;
    // if (!eventId || eventName !== selectedEventUrlName) {
    //   if (eventName) {
    //     const event = events.find((e) => isUrlNamesMatch(e.urlName, eventName));
    //     eventId = event ? event.id : null;
    //   }
    // }

    sportsDispatcher({
      type: SportsActions.SPORT_STATE_UPDATE,
      payload: {
        selectedSportId: sportId,
        selectedCategoryId: categoryId,
        selectedTournamentId: tournamentId,
        selectedEventId: selectedEventIdParam,
      },
    });
  }, [
    selectedSportUrlName,
    selectedCategoryUrlName,
    selectedTournamentUrlName,
    selectedEventUrlName,
    selectedEventIdParam,
    sports,
    categories,
    tournaments,
    events,
  ]);

  useEffect(() => {
    Bus.emit(appEvents.resetScroll);

    sportsDispatcher({
      type: SportsActions.SPORT_STATE_UPDATE,
      payload: {
        selectedSportId: null,
        selectedCategoryId: null,
        selectedTournamentId: null,
        selectedEventId: selectedEventIdParam,
        selectedSportUrlName: sportName,
        selectedCategoryUrlName: categoryName,
        selectedTournamentUrlName: tournamentName,
        selectedEventUrlName: eventName,
      },
    });
  }, [sportName, categoryName, tournamentName, eventName, selectedEventIdParam]);

  return children;
};
export default SportsPathHandler;
