import React from 'react';
import EventParticipants from './EventParticipants';

const EventParticipantsBlock = ({ withSportIcon, event }) =>
  withSportIcon ? (
    <div className='event__info-block'>
      <div className='event-participants-wrapper'>
        <EventParticipants event={event} withSportIcon />
      </div>
      <div className='event__info-tournament'>
        <span className='event__info-tournament--category'>{event.categoryName} - </span>
        <span className='event__info-tournament--tournament'>{event.tournamentName}</span>
      </div>
    </div>
  ) : (
    <EventParticipants event={event} />
  );

export default EventParticipantsBlock;
