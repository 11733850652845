import React from 'react';
import { SettingsManager, SiteConfigManager, useSettings } from '@apollo/core';
import ToggleCheckbox from '../../../shared/components/ToggleCheckbox/ToggleCheckbox';
import Label from '../../../shared/components/I18n/Label';

const ThemeOptions = {
  dark: 'dark',
  light: 'light',
};

const ThemeSettings = ({ disabled = false }) => {
  const {
    theme,
    debugMode,
  } = useSettings();

  const enableDebugToggle = SiteConfigManager.getConfig('config.config.enable_debug_toggle');

  const toggleThemeLightDark = React.useCallback(
    () => {
      const newTheme = theme === ThemeOptions.dark
        ? ThemeOptions.light
        : ThemeOptions.dark;
      SettingsManager.updateSettings({ theme: newTheme });
    },
    [theme],
  );

  // This is temporary - For debug and should be removed once multi-providers is feature complete
  const toggleDebugMode = React.useCallback(
    () => {
      SettingsManager.updateSettings({ debugMode: !debugMode });
    },
    [debugMode],
  );

  return (
    <div className='settings-content-controls settings-content-controls--theme'>
      <div className='settings-content-controls__title'>
        <Label message='theme_settings' />
      </div>
      <div className='settings-content-controls__input settings-content-controls__input--checkbox'>
        <div className='container'>
          <div className='switchBlockInfo'>
            <Label message='change_theme_light_dark' />
          </div>
          <ToggleCheckbox
            disabled={disabled}
            checked={theme === ThemeOptions.dark}
            onChangedHandler={toggleThemeLightDark}
          />
        </div>

        {enableDebugToggle && (
        <div className='container'>
          <div className='switchBlockInfo'>
            <Label message='toggle_providers_mode' />
          </div>

          <ToggleCheckbox
            disabled={disabled}
            checked={debugMode}
            onChangedHandler={toggleDebugMode}
          />
        </div>
        )}
      </div>

    </div>
  );
};

export default ThemeSettings;
