import React from 'react';
import { NavLink } from 'react-router-dom';
import Label from '../../../../shared/components/I18n/Label';

const PaymentMethodItem = ({ id, name, route, type, depositType }) => {
  const paymentRoute = `${route}/${id}`;
  // const { pathname } = useLocation();
  // const isPathActive = !!matchPath(pathname, paymentRoute);
  // if (isPathActive) {
  //   paymentRoute = route;
  // }
  return (
    <NavLink to={paymentRoute} className='payment-method-item' activeClassName='active'>
      <div className='wrapper'>
        <div className='wrapper__payments'>
          <div className={`logo logo--${depositType?.toLowerCase()}`} />
          <div className='content'>
            <div className='name'>{name}</div>
            <div className='description'>
              <Label message={`${type} ${name} description`} />
            </div>
          </div>
          <span className='icon AIcon-angle-right' />
        </div>
      </div>
    </NavLink>
  );
};
export default PaymentMethodItem;
