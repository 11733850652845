import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { generateRaceLink } from '../../../core/utils';

const RaceLink = ({
  race = {},
  raceDate = race.meeting?.date,
  raceType = race.raceType?.typeName,
  raceCountry = race.venue?.country,
  raceVenue = race.venue?.venueName,
  raceNumber = race.number,
  marketTab,
  marketType,
  active,
  children,
  className,
  refProps,
}) => {
  const toUrl = generateRaceLink({
    raceDate,
    raceType,
    raceCountry,
    raceVenue,
    raceNumber,
    marketTab,
    marketType,
  });

  const toRaceMain = generateRaceLink({
    raceDate,
    raceType,
  });

  const classes = cx(className, {
    active,
  });

  const scrollToTopHandler = React.useCallback(() => {
    // const scrollableElement = document.querySelector('.main__body');
    // scrollableElement.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth'
    // })
  }, []);

  return (
    <Link
      to={active ? toRaceMain : toUrl}
      className={classes}
      onClick={scrollToTopHandler}
      ref={refProps}
    >
      {children}
    </Link>
  );
};
export default RaceLink;
