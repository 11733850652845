import React from 'react';
import ReactDOM from 'react-dom';

const NOTIFICATIONS_ROOT_NODE_ID = 'notifications-root';

const NotificationsPortal = ({ children, className = '' }) => {
  const rootRef = React.useRef(null);
  if (!rootRef.current) {
    rootRef.current = document.getElementById(NOTIFICATIONS_ROOT_NODE_ID);
  }

  return ReactDOM.createPortal(
    <div className={`modal modal--notifications ${className || ''}`}>
      <div className='modal__backdrop' />
      <div className='modal__container modal__container--scrollable'>
        <div className='modal__content'>{children}</div>
      </div>
    </div>,
    rootRef.current,
  );
};
export default NotificationsPortal;
