import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { forEach, isEmpty, map } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Bus } from '@apollo/core';
import LimitInput from './LimitInput';
import useTranslate from '../../../../../shared/components/I18n/Interpreter';
import Label from '../../../../../shared/components/I18n/Label';
import FormCom from '../../../../../shared/components/Form/FormCom';
import {
  getLimitWarning,
  showLimitsWereEditedNotification,
} from '../../../../../shared/components/Form/formConfig/responsibleGaming';
import HiddenField from '../../../../../shared/components/Form/Fields/HiddenField';
import SelectField from '../../../../../shared/components/Form/Fields/SelectField';
import TextField from '../../../../../shared/components/Form/Fields/TextField';

const LimitsEdit = ({
  regulationTypeState,
  activeRegulationState,
  updateLimitsCallback,
  label,
  action,
  toggleEdit,
  currencySymbol,
  minDepositAmount = 1000,
}) => {
  const t = useTranslate();

  const [activeType, setActiveType] = useState(
    activeRegulationState?.type || regulationTypeState?.[0]?.type,
  );

  // const onTypeChangeHandler = useCallback((e) => {
  //   setActiveType(e.target.value);
  // }, [setActiveType]);

  const onTypeChangeHandler = useCallback(
    (value) => {
      setActiveType(value);
    },
    [setActiveType],
  );

  const formFields = useMemo(() => {
    const fields = [];
    const initialValues = {};

    forEach(regulationTypeState, ({ type: name, future, value }) => {
      const isActive = name === activeType;
      fields.push({
        name,
        number: 100,
        component: isActive ? LimitInput : HiddenField,
        label: name,
        withTranslate: true,
        rules: [
          {
            type: 'MIN',
            message: 'minimum_deposit_amount',
            ruleOption: minDepositAmount, // $10.00
          },
        ],
        value: isActive ? value : null,
        warning: getLimitWarning(future),
      });
    });

    return {
      name: 'selfRegulationLimits',
      config: {
        initialValues,
      },
      fields,
      action: '',
      autocomplete: false,
      number: 100,
    };
  }, [regulationTypeState, minDepositAmount, activeType]);

  const submitCallback = useCallback(
    (data) => {
      if (data) {
        const limits = updateLimitsCallback(data);
        showLimitsWereEditedNotification({
          type: label,
          limits,
          currencySymbol,
          translate: t,
        });
      }

      Bus.send({
        event: Bus.events.profile.accountUpdate,
        data: { limitsSet: true },
      });

      toggleEdit();
    },
    [updateLimitsCallback, toggleEdit, label, currencySymbol, t],
  );

  const buttonLeftContent = useMemo(
    () => (
      <button type='button' className='bCancel' onClick={toggleEdit}>
        <Label message='cancel' />
      </button>
    ),
    [toggleEdit],
  );

  return (
    <div className='form-limits-edit'>
      <div className='details__inputs'>
        <div className='title'>
          <Label className='limit-title' message={label} />
        </div>

        {regulationTypeState.length > 1 ? (
          <SelectField
            field={{
              name: 'limit_period',
              label: 'limit_period',
              placeholder: 'limit_period',
              value: activeType,
              translateOptions: false,
              options: map(regulationTypeState, ({ type: name }) => ({ text: name, value: name })),
            }}
            onChange={onTypeChangeHandler}
          />
        ) : (
          <TextField
            field={{
              name: 'limit_period',
              label: 'limit_period',
              placeholder: 'limit_period',
              value: activeType,
              translateOptions: false,
              readOnly: true,
            }}
            onChange={onTypeChangeHandler}
          />
        )}

        {/*
        <div className='ui__title'>
          <select className='' onChange={onTypeChangeHandler} value={activeType}>
            {map(regulationTypeState, ({ type: name }) => (
              <option key={name} value={name}>
                {t(name)}
              </option>
            ))}
          </select>
        </div>
        */}

        <FormCom
          action={action}
          onSubmit={submitCallback}
          form={formFields}
          className='active'
          buttonText='submit'
          buttonLeftContent={buttonLeftContent}
        />
      </div>
    </div>
  );
};

LimitsEdit.propTypes = {
  regulationTypeState: PropTypes.array.isRequired,
  updateLimitsCallback: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default withRouter(LimitsEdit);
