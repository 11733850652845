import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';
import { BetManager, RacingManager, useApplicationState } from '@apollo/core';
import { BET_EVENT_TYPE } from '@apollo/core/src/constants';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import RaceLink from '../../../../shared/components/Racing/RaceLink';
import ToastManager from '../../../../core/ToastManager';
import PercentageInput from '../../../../shared/components/Form/PercentageInput/PercentageInput';
import MoneyInput from '../../../../shared/components/Form/MoneyInput/MoneyInput';
import { oddsAnimation } from '../../../../core/utils';
import SelectedOddsButtons from '../../../../shared/components/SelectedOdds/SelectedOddsButtons';
import KeyPad from '../../../Betslip/PlaceBetControls/KeyPad';
import { RaceMainTabs } from '../../../../core/constants';
import Loader from '../../../../shared/components/Loader';

const Selections = ({ legs, selectedBetType }) => {
  const t = useTranslate();
  const { layout } = useApplicationState();
  const [isExpanded, setIsExpanded] = useState(false);
  const [outcome, setOutcome] = useState(null);
  const [isFetchingOutcome, setIsFetchingOutcome] = useState(false);

  const [amount, setAmount] = useState();

  const [racingState, racingDispatcher] = useRacingState();

  const {
    activeRace,
    activeMeet,
    multilegsSelections = [],
    marketsByType,
    raceNumber,
    activeMarketTypeId,
  } = racingState;

  // variable for storing latest API request Promise object,
  // which can be used to remedy racing between simultaneous requests
  const latestRequest = useRef();

  useEffect(() => {
    const selections = multilegsSelections;

    if (
      selections.length &&
      selections.length === legs.length &&
      selections.filter((r) => r?.length).length === selections.length
    ) {
      // has any selection for each leg
      setIsFetchingOutcome(true);
      // both local variable and "ref" are set to the same value.
      // when promise is settled (with data or error) local variable should be compared to "global" "ref"
      // and don't change state if they don't match (this happens only if later request reassigned the ref,
      // then state will be changed only in response to the latest (latest started) request regardless if response took more
      // or less time than the previous request)
      const currentRequest = (latestRequest.current = RacingManager.getMultilegsOutcome({
        marketId: marketsByType[selectedBetType]?.marketId,
        selections,
      })
        .then(({ outcome }) => {
          if (currentRequest === latestRequest.current) {
            if (
              selections.length &&
              selections.length === legs.length &&
              selections.filter((r) => r?.length).length === selections.length
            ) {
              // recheck if combination valid
              setOutcome(outcome);
            } else {
              setOutcome(null);
            }

            setIsFetchingOutcome(false);
          }
        })
        .catch((err) => {
          if (currentRequest === latestRequest.current) {
            setIsFetchingOutcome(false);
            ToastManager.error(() => err.message);
            /* racingDispatcher({
              type: RacingActions.RACING_STATE_UPDATE,
              payload: {
                multilegsSelections: prevSelections,
              },
            }); */
          }
        }));
    } else {
      setOutcome(null);
    }
  }, [legs.length, marketsByType, selectedBetType, multilegsSelections]);

  const clearSelection = useCallback(() => {
    racingDispatcher({
      type: RacingActions.RACING_STATE_UPDATE,
      payload: {
        multilegsSelections: [],
      },
    });
    setOutcome(null);
    setAmount(null);
  }, [racingDispatcher]);

  const addToBet = useCallback(
    (e) => {
      if (layout.mobileDevice) {
        oddsAnimation(
          e.target.classList.contains('btn') ? e.target : e.target.parentNode,
          true,
          outcome?.combos ? outcome?.combos : ''
        );
      }
      BetManager.selectBet({
        betEventType: BET_EVENT_TYPE.RACING,
        event: activeRace,
        eventId: activeRace.raceId,
        marketTypeId: marketsByType[selectedBetType].type,
        marketId: marketsByType[selectedBetType].marketId,
        outcomeId: outcome.outcomeId,
        amount,
        betGroups: [0],
        isNew: true,
      });

      ToastManager.success(() => t('added to betslip'));

      // clearSelection();
    },
    [activeRace, outcome, marketsByType, t]
  );

  const handleBetAmountChange = useCallback((amount) => {
    setAmount(amount);
  }, []);

  const handleBetPercentageChange = useCallback(
    (amount) => {
      setAmount(amount * (outcome?.combos || 1));
    },
    [outcome]
  );

  const currentLegIndex = useMemo(() => legs.indexOf(raceNumber), [legs, raceNumber]);

  const isDisabled = !outcome?.combos || isFetchingOutcome;

  return (
    <div className='selectedOdds'>
      <div className='legs-navigation'>
        <div className='legs-navigation-leg legs-navigation-leg--prev'>
          {currentLegIndex > 0 ? (
            <RaceLink
              className='legs-navigation-link'
              raceDate={activeMeet.date}
              raceType={activeMeet.raceType.typeName}
              raceCountry={activeMeet.venue.country}
              raceVenue={activeMeet.venue.venueName}
              raceNumber={legs[currentLegIndex - 1]}
              marketType={activeMarketTypeId}
              marketTab={RaceMainTabs.MULTILEGS_TAB}
            >
              <span className='icon AIcon-angle-left' />
              {`${t('legs')} ${currentLegIndex}`}
            </RaceLink>
          ) : null}
        </div>
        <div className='legs-navigation-leg legs-navigation-leg--current'>
          {`${t('legs')} ${currentLegIndex + 1}: R${raceNumber}`}
        </div>
        <div className='legs-navigation-leg legs-navigation-leg--next'>
          {currentLegIndex + 1 < legs.length ? (
            <RaceLink
              className='legs-navigation-link'
              raceDate={activeMeet.date}
              raceType={activeMeet.raceType.typeName}
              raceCountry={activeMeet.venue.country}
              raceVenue={activeMeet.venue.venueName}
              raceNumber={legs[currentLegIndex + 1]}
              marketType={activeMarketTypeId}
              marketTab={RaceMainTabs.MULTILEGS_TAB}
            >
              {`${t('legs')} ${currentLegIndex + 2}`}
              <span className='icon AIcon-angle-right' />
            </RaceLink>
          ) : null}
        </div>
      </div>
      {multilegsSelections?.length ? (
        <div className='collapse__wrapper'>
          <div className='header' onClick={() => setIsExpanded(!isExpanded)}>
            <div className='icon'>
              <span className={`AIcon-angle-${isExpanded ? 'down' : 'up'}`} />
            </div>
            <div className='title'>{`${isExpanded ? 'Hide' : 'Show'}`} Selections</div>
          </div>
          {isExpanded ? (
            <div className='content'>
              <div>
                {legs.map((leg, index) => (
                  <div key={leg} className='row'>
                    <div className='index'>
                      {t('legs')}
                      {index + 1}: &nbsp;
                    </div>
                    <div className='name'>
                      {multilegsSelections[index]?.sort((a, b) => a - b).join(', ') || '-'}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div className='action_section'>
            <div className='legs'>
              <span>{t('combos')}</span>
              {isFetchingOutcome ? (
                <Loader className='fetchingOutcome' />
              ) : (
                <span className='legs__combos'>{outcome?.combos || 0}</span>
              )}
            </div>
            <div className='add_to_bet add_to_bet--with-amount'>
              <div className='add_to_bet__amount'>
                {!outcome && !isFetchingOutcome ? (
                  <span className='multi-add-selection'>{t('add_selection')}</span>
                ) : (
                  <>
                    <PercentageInput
                      value={amount}
                      combos={outcome?.combos || 1}
                      onChange={handleBetPercentageChange}
                      placeholder='betslip_percentage'
                      disabled={isDisabled}
                    />
                    <MoneyInput
                      value={amount}
                      onChange={handleBetAmountChange}
                      withTranslate
                      placeholder='stake'
                      disabled={isDisabled}
                    />
                  </>
                )}
              </div>

              <SelectedOddsButtons
                addToBet={(e) => addToBet(e)}
                addDisabled={isDisabled}
                clearSelection={clearSelection}
                clearDisabled={!multilegsSelections?.length}
              />
            </div>
          </div>
        </div>
      ) : null}
      {layout.keyPadMoneyInput && layout.mobileDevice ? (
        <KeyPad key={layout.keyPadMoneyInput} inputParentSelector='.main__body--race-page' />
      ) : null}
    </div>
  );
};

export default Selections;
