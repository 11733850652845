import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DateUtils } from '@apollo/core';
import { RaceTypeAll } from '@apollo/core/src/constants';
import { RacingActions, useRacingState } from '@apollo/core/src/state/racing/racing';

const RacingPathHandler = (props) => {
  const { children } = props;

  const routeParams = useParams();
  const { raceDate, raceType, raceCountry, raceVenue, raceNumber, marketTab, marketType } = routeParams;

  const [racingState, racingDispatcher] = useRacingState();
  const {
    meetings,
    races,
    raceDate: stateRaceDate,
    activeRaceId,
    loading,
    activeLoaders,
    initialized,
  } = racingState;

  useEffect(() => {
    const compareRaceNumber = (n1, n2) => String(n1) === String(n2) || !n2;
    const isMeetingLoaded = meetings.length > 0;

    const meetingRaces = isMeetingLoaded
      && meetings.find(
        (meeting) => meeting?.date === raceDate
          && meeting?.country === raceCountry
          && meeting?.venueName === raceVenue,
      )?.races;

    const race = (meetingRaces && meetingRaces?.find((r) => compareRaceNumber(r.number, raceNumber))) || false;

    /* const activeMeet = (meetings || []).find((m) => {

    raceType: {typeName:nr.type},
            meeting: {meetingId: nr.meetingId, date:nr.date},
            venue: {venueId: nr.venueId, country: nr.country, venueName: nr.venueName}

      if (m.date !== raceDate) {
        return false;
      }
      if (m.raceType.typeName !== raceType) {
        return false;
      }
      if (m.venue.country !== raceCountry) {
        return false;
      }
      if (m.venue.venueName !== raceVenue) {
        return false;
      }
      return true;
    });

    if (activeMeet == null) {
      return;
    }

    const rn = raceNumber || '1';
    let race = activeMeet.races.find((r) => r.number.toString() === rn);
    if (!race) {
      race = activeMeet.races?.[0];
    } */

    if (race && race.raceId !== activeRaceId) {
      racingDispatcher({
        type: RacingActions.RACING_SET_ACTIVE_RACE_ID,
        payload: race.raceId,
      });
    }
  }, [meetings, raceDate, raceType, raceCountry, raceVenue, raceNumber]);

  useEffect(() => {
    racingDispatcher({
      type: RacingActions.RACING_PATH_UPDATE,
      payload: {
        raceDate: raceDate ?? DateUtils.todayDateKey(),
        raceType: raceType ?? RaceTypeAll.typeName,
        raceCountry,
        raceVenue,
        raceNumber,
        marketTab,
        activeMarketTypeId: marketType,
      },
    });
  }, [
    stateRaceDate,
    raceDate,
    raceType,
    raceCountry,
    raceVenue,
    raceNumber,
    marketTab,
    marketType,
  ]);

  // if (!initialized || loading) {
  //  return <RaceLoader />;
  // }

  return children;
};
export default RacingPathHandler;
