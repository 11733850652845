import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { sortBy } from 'lodash';
import SportListItemHeader from '../../ResultsPage/SportList/SportListItemHeader';
import Label from '../../../shared/components/I18n/Label';
import { SportAllLink } from '../../../shared/components/Sport/SportLinks';
import TournamentsListItem from '../SportsSidebar/Tournaments/TournamentsListItem';
import CategoryList from './CategoryList';
import { SPORTS_BY_CATEGORY } from '../../../core/constants';

const SportListItem = (props) => {
  const { onToggleActiveSport, sport, active, tournaments } = props;

  const sortedTournaments = useMemo(
    () => sortBy(tournaments, (t) => t.position).filter((t) => t.totalCount > 0),
    [tournaments]
  );

  const [activeCategory, setActiveCategory] = useState();
  const toggleActiveCategory = useCallback(
    (id) => {
      if (id === activeCategory) {
        setActiveCategory(null);
      } else {
        setActiveCategory(id);
      }
    },
    [activeCategory]
  );

  const className = cx('bottom__block two', {
    active,
  });

  return (
    <>
      <SportListItemHeader
        className={className}
        sportName={sport.name}
        toggleExpand={onToggleActiveSport}
        sportUrlName={sport.urlName}
        active={active}
        id={sport.id}
      />
      {active && (
        <>
          {SPORTS_BY_CATEGORY.includes(sport.id) ? (
            <div className='sport-tree__category-list'>
              <CategoryList
                sportId={sport.id}
                activeCategory={activeCategory}
                toggleActiveCategory={toggleActiveCategory}
              />
            </div>
          ) : (
            sortedTournaments.map((tournament) => (
              <TournamentsListItem key={tournament.id} tournament={tournament} />
            ))
          )}
          <SportAllLink
            className='sidebar-tree__section-item sidebar-tree__section-item--tournament'
            sportUrlName={sport.urlName}
          >
            <Label message='view all' />
          </SportAllLink>
        </>
      )}
    </>
  );
};

export default SportListItem;
