import React, { useCallback } from 'react';

import { AccountManager, NotificationManager, useApplicationState } from '@apollo/core';
import Limits from './Limits/Limits';
import useTranslate from '../../../../shared/components/I18n/Interpreter';
import StaticBlock from '../../../../shared/components/StaticBlock/StaticBlock';
import { cmsStaticBlock } from '../../../../core/constants';

// const SPENT = 'SPENT';
// const GAME_SPENT = 'GAME_SPENT';
// const SPORT_SPENT = 'SPORT_SPENT';
const DEPOSIT = 'DEPOSIT';

const SelfRegulation = () => {
  const { account } = useApplicationState();
  const t = useTranslate();

  const doNotSetHandler = useCallback(() => {
    AccountManager.updateLimitsSet({ limitsSet: true });
    // Prefer not to ask twice
    // NotificationManager.display({
    //   type: 'confirm',
    //   title: 'do_not_set_limits',
    //   message: 'confirm_do_not_set_limits',
    //   data: {
    //     onConfirm: () => {
    //       AccountManager.updateLimitsSet({ limitsSet: true });
    //     },
    //   },
    // });
  }, []);

  return (
    <div className='main__page__account__block__content'>
      {account && !account.limitsSet && (
        <div className='wrapper wrapper--limits-set'>
          <div className='details-profile details-profile--limits-set'>
            <StaticBlock options={{ source: cmsStaticBlock.SetSpendingLimits }} />

            <button type='button' className='editLink editLink--danger' onClick={doNotSetHandler}>
              <span className='icon AkIcon-close-icon' />
              {t('do_not_set_limits')}
            </button>
          </div>
        </div>
      )}

      <div className='wrapper wrapper--self-reg'>
        <div className='details-profile details-profile--self-reg'>
          <Limits type={DEPOSIT} />

          <div className='deposit-limits-desc'>{t('deposit_limits_desc')}</div>

          <div className='deposit-limits-note'>{t('deposit_limits_note')}</div>
        </div>

        {/*
        <div className='main__page__account__block__content'>
          <Limits type={SPENT} />
           <Limits type={GAME_SPENT} />
           <Limits type={SPORT_SPENT} />
        </div>
        */}
      </div>
    </div>
  );
};

export default SelfRegulation;
