import React, { useMemo } from 'react';
import { getAvailablePages, SiteConfigManager, useApplicationState } from '@apollo/core';
import { availableClientProfileRoutes, COMPONENT_TYPES } from '@apollo/routing';
import LinkList from './LinkList';
import { THEME_NAMES } from '../../core/constants';
import { LocalConfig } from '../../core/utils';

const { CLIENT_PROFILE } = COMPONENT_TYPES;

const ClientProfileLinks = ({ tabs, availableRoutes, parentRoute }) => {
  const { layout } = useApplicationState();
  const clientProfilePath = SiteConfigManager.getComponentPath(CLIENT_PROFILE);

  // todo: Are we going to have 10+ condition for each brand? Need a better solution
  const themeName = LocalConfig.get('THEME_NAME')?.toLowerCase();
  const isHorizontalMenu = themeName === THEME_NAMES.betMax && !layout.mobileDevice;

  const { tabs: clientProfileTabs = [] } = SiteConfigManager.getComponentOptions(CLIENT_PROFILE);

  const mainTabs = useMemo(
    () => getAvailablePages(availableClientProfileRoutes, clientProfileTabs),
    [clientProfileTabs]
  );

  const subTabs = useMemo(() => getAvailablePages(availableRoutes, tabs), [tabs]);

  return (
    <>
      {isHorizontalMenu && (
        <div className='navigation navigation--primary navigation--sticky'>
          <LinkList
            array={mainTabs}
            parentPath={clientProfilePath}
            isScroll={false}
            scrollIntoView
          />
        </div>
      )}
      {subTabs && subTabs.length > 0 ? (
        <div className='navigation navigation--secondary'>
          <LinkList
            array={subTabs}
            parentPath={`${clientProfilePath}/${parentRoute}`}
            isScroll={false}
          />
        </div>
      ) : null}
    </>
  );
};

export default ClientProfileLinks;
