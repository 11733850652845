import React, { useEffect, useMemo, useState } from 'react';
import { isArray, isEmpty } from 'lodash';
import { Bus } from '@apollo/core';
import { RacingSelector, useRacingState } from '@apollo/core/src/state/racing/racing';
import BonusBanner from '../../Bonus/BonusBanner';
import RaceLink from '../../Racing/RaceLink';
import { RacingIcon } from '../../Racing/RacingIcon';
import RaceCountdown from '../../Racing/RaceCountdown/RaceCountdown';
import RaceRunner from '../../../../features/Racing/RacePage/RaceRunner';
import RaceOutcome from '../../../../features/Racing/RaceOutcome/RaceOutcome';
import RaceFlucs from '../../../../features/Racing/raceFlucs/RaceFlucs';
import { RACE_STATUS } from '../../../../core/constants';

const RacingFeaturedEvent = ({ data }) => {
  const [racingState] = useRacingState();
  const [races, setRaces] = useState({});

  // Prevent falling into an endless loop if the API doesn't return a result
  const [attemptedToLoad, setAttemptedToLoad] = useState(false);

  const isLoading = RacingSelector.selectIsLoading();

  const raceIds = useMemo(() => {
    if (!isArray(data)) {
      return [];
    }
    return data.map(({ race }) => race?.id).filter((id) => !!id);
  }, [data]);

  useEffect(() => {
    if (isLoading || isEmpty(raceIds)) {
      return;
    }

    const discoveredRaces = {};

    raceIds.forEach((raceId) => {
      const cachedRace = racingState.races?.find((r) => r.raceId === raceId);
      if (cachedRace && !isEmpty(cachedRace.runners)) {
        const { runners } = cachedRace;
        const fixedWinOutcomes =
          cachedRace.markets?.find((m) => m.type === 'F_WIN')?.outcomes || [];

        const runnersWithOutcomes = runners.map((runner) => {
          const outcome = fixedWinOutcomes.find(
            (o) => String(o.selection) === String(runner.number)
          );
          return {
            ...runner,
            outcome,
          };
        });

        const favourites = runnersWithOutcomes
          .filter((r) => !!r.outcome)
          .sort((a, b) => a.outcome.odds - b.outcome.odds)
          .slice(0, 3);

        discoveredRaces[cachedRace.raceId] = {
          ...cachedRace,
          favourites,
          fixedWinOutcomes,
        };
      } else if (!attemptedToLoad) {
        Bus.emit(Bus.events.racing.loadSingleRace, {
          raceId,
        });
        setAttemptedToLoad(true);
      }
    });
    setRaces(discoveredRaces);
  }, [attemptedToLoad, isLoading, raceIds, racingState.races]);

  if (isEmpty(races)) {
    return null;
  }

  return data.map((d, idx) => {
    const { widgetTitle, title, race } = d;
    const fullRace = races[race?.id];

    if (
      isEmpty(fullRace) ||
      isEmpty(fullRace?.favourites) ||
      fullRace?.status !== RACE_STATUS.OPEN
    ) {
      return null;
    }

    const { favourites } = fullRace;

    return (
      <div
        key={`${fullRace.raceId}-${idx}`}
        className='cms-widget cms-widget--racing-featured-event'
      >
        <div className='cms-widget__col'>
          <h4 className='cms-widget__subtitle'>{widgetTitle}</h4>
          <h3 className='cms-widget__title'>
            <RaceLink
              className='cms-widget__title-link'
              raceDate={fullRace.meeting.date}
              raceType={fullRace.raceType.typeName}
              raceCountry={fullRace.venue.country}
              raceVenue={fullRace.venue.venueName}
              raceNumber={fullRace.number}
            >
              {title}
            </RaceLink>
          </h3>
        </div>
        <div className='cms-widget__col'>
          <BonusBanner race={fullRace} />
          <div className='cms-widget__race'>
            <div className='cms-widget__race-details'>
              <RaceLink
                className='cms-widget__race-header'
                raceDate={fullRace.meeting.date}
                raceType={fullRace.raceType.typeName}
                raceCountry={fullRace.venue.country}
                raceVenue={fullRace.venue.venueName}
                raceNumber={fullRace.number}
              >
                <RacingIcon id={fullRace.raceType?.id} name={fullRace.raceType?.typeName} />
                <p className='cms-widget__race-header__venue'>{fullRace.venue.venueName}</p>
                <div className='cms-widget__race-header__details'>
                  <p>R{fullRace.number}</p>
                  <p>{fullRace.distance}m</p>
                </div>
                <RaceCountdown
                  key={`${fullRace?.raceId}-${fullRace?.status}-${fullRace?.result}`}
                  date={new Date(fullRace.startTime)}
                  completeText={fullRace.result || fullRace.status}
                />
              </RaceLink>
            </div>
            {favourites.map((r) => (
              <div key={r.runnerId} className='cms-widget__runner'>
                <RaceRunner runner={r} activeRace={fullRace} showRunnerImage={!r.scratched} />
                <RaceFlucs activeRace={fullRace} runner={r} />
                <RaceOutcome
                  key={r.outcome.outcomeId}
                  meet={fullRace.meetingId}
                  race={fullRace}
                  runner={r}
                  market={fullRace.markets[0]}
                  outcome={r.outcome}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  });
};

export default RacingFeaturedEvent;
