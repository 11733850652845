import React from 'react';

const marketTypeDescriptions = {
  /*
   * NRL markets
   */
  // Match Winner
  232064: 'If the venue of a match is changed from the one advertised, all bets on that match will be voided. Includes extra time. Dead heat rules apply.',
  // Handicap
  232066: 'Includes extra time.',
  // Winning Margin
  233214: 'Includes extra time. If the match is a draw after extra time all bets in this market will be deemed losers as neither team has won the match.',
  // Total
  232065: 'Includes extra time.',

  /*
   * AFL markets
   */
  // Match Winner
  232016: 'If the venue of a match is changed from the one advertised, all bets on that match will be voided. In the event of a draw, Dead Heat Rules apply.',

  /*
   * Soccer
   */
  // Handicap
  155864: 'Handicaps that are in quarters such as -1.25 (this means two bets at  -1 and -1.5). Bets on these markets are split with half the stake on each of the handicaps at the listed odds.',
  // Total
  156164: 'Totals are represented as two totals with a single odds value or as a quarter total such as 2.75 (meaning 2.5 and 3.0). Bets on these markets are split with half the stake on each of the totals at the listed odds.',
};

export const getMarketTypeDescriptions = (id) => {
  if (!marketTypeDescriptions[id]) {
    return null;
  }

  return (typeof marketTypeDescriptions[id] === 'string' ? [marketTypeDescriptions[id]] : marketTypeDescriptions[id])
    .map((description, index) => (
      <p key={index}>{description}</p>
    ));
};
